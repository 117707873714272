import React, {Component} from 'react'
import Chart from 'chart.js/auto'

let COLOR           = 'rgba(153,182,78,0.69)'
let backgroundColor = []

// TODO: consolidate ith LineChartGoalie.js

function setColor(league, level) {
  if (league === 'OMGHA') {
    COLOR = 'rgba(234,112,13,0.69)'
  } else if (league === 'ASSOC') {
    COLOR = 'rgba(234,112,13,0.69)'
  } else if (league === 'SDP') {
    COLOR = 'rgba(25,158,229,0.69)'
  } else if (league === 'AAA') {
    COLOR = 'rgba(25,158,229,0.69)'
  } else if (league === 'MN MADE') {
    COLOR = 'rgba(33,30,92, 0.69)'
  } else if (league === 'HockeyFinder') {
    if (level === '1.0') {
      COLOR = 'rgba(153,182,78,0.19)'
    } else if (level === '1.5') {
      COLOR = 'rgba(153,182,78,0.69)'
    } else if (level === '2.0') {
      COLOR = 'rgba(153,182,78,0.69)'
    } else if (level === '2.5') {
      COLOR = 'rgba(153,182,78,0.69)'
    } else if (level === '3.0') {
      COLOR = 'rgba(153,182,78,0.69)'
    } else if (level === '3.5') {
      COLOR = 'rgba(153,182,78,0.69)'
    } else if (level === '4.0') {
      COLOR = 'rgba(153,182,78,0.69)'
    } else if (level === '4.5') {
      COLOR = 'rgba(153,182,78,0.69)'
    }
  }else {
    // OTHER
    COLOR = 'rgba(137,17,164,0.69)'
  }
  backgroundColor.push(COLOR)
}

export default class LineChart extends Component {

  state = {
    fromDate                    : "2022-10-06",
    toDate                      : this.generateDate(),
    currentLeagueFilter         : "ALL",
    currentSecondaryLeagueFilter: undefined
  }

  generateDate() {
    let mmddyyyy = new Date().toLocaleString().split(",")[0].replace('/', '-').replace('/', '-')
    let yyyy     = mmddyyyy.split('-')[2]
    let mm       = mmddyyyy.split('-')[0]
    let dd       = mmddyyyy.split('-')[1]
    console.log(yyyy + '-' + mm + '-' + dd)
    return yyyy + '-' + mm + '-' + dd
  }

  chart = undefined

  generateRef() {
    return React.createRef()
  }

  chartRef = this.generateRef()

  componentDidMount() {
    console.log('PRE this.chartRef ', this.chartRef)
    this.createChart()
  }

  createChart() {
    const ctx = this.chartRef.current.getContext("2d")
    console.log('params ', this.props, this.ChartID)
    let STAT = this.props.params[0]
    ctx.id   = `myChart_${STAT}`

    let chartDataObject = this.buildDataset("ALL")

    this.chart = new Chart(ctx, {
      data   : {
        labels  : chartDataObject.LABELS,
        datasets: chartDataObject.DATASETS
      },
      options: {
        plugins    : {
          legend: false
        },
        transitions: {
          show: {
            animations: {
              x: {
                from: 0
              },
              y: {
                from: 0
              }
            }
          },
          hide: {
            animations: {
              x: {
                to: 0
              },
              y: {
                to: 0
              }
            }
          }
        }
      }
    })
  }

  buildDataset(leagueFilter, secondaryLeagueFilter, fromDate, toDate) {
    let STAT     = this.props.params[0]
    let NAME     = this.props.params[1].length > 0 ? this.props.params[1][0].name : "This Player"
    let DATA     = this.props.params[1][0].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    let LABELS   = []
    let yAxes    = [{
      labels: ['A', 'B', 'C', 'A', 'B', 'C', 'A', 'B', 'C']
    },
                    {
                      id       : 'yAxis1',
                      type     : 'category',
                      offset   : true,
                      gridLines: {
                        offsetGridLines: true
                      }
                    }
    ]
    let DATASETS = [{
      type           : 'line',
      data           : [],
      label          : `Average Teammate`,
      borderColor    : "#3F3F3F",
      backgroundColor: "#3F3F3F",
      fill           : false,
      pointRadius    : 0,
      tension        : 0.5,
      borderWidth    : 1
    }, {
      type           : 'bar',
      data           : [],
      label          : `${NAME}'s ${STAT}`,
      backgroundColor: backgroundColor,
      fill           : false,
      borderWidth    : 1
    }, {
      type           : 'line',
      data           : [],
      label          : `Top Teammates`,
      borderColor    : "rgba(0,0,0,0.0)",
      backgroundColor: "rgba(0,0,0,0.1)",
      fill           : true,
      pointRadius    : 0,
      tension        : 0.5,
      borderWidth    : 1
    }]

    for (let i = 0; i < DATA.length; i++) {
      // set LABELS
      // this date format is  mm/dd/yyyy, HH[AM,PM]
      let date     = DATA[i].date.split(/:[0-9][0-9]/)[0] + DATA[i].date.split(/[0-9] /)[1]
      let gameDate = new Date(date.split(',')[0])
      let league   = DATA[i].league
      let level   = DATA[i].level

      console.log(
        'fromDate = ', this.state.fromDate,
        'toDate = ', this.state.toDate
      )

      let fromDate             = this.state.fromDate.split('-').reverse()
      let toDate               = this.state.toDate.split('-').reverse()
      // mm/dd/yyyy
      let restructuredFromDate = new Date(fromDate[1] + '/' + fromDate[0] + '/' + fromDate[2])
      let restructuredToDate   = new Date(toDate[1] + '/' + toDate[0] + '/' + toDate[2])

      // console.log(restructuredFromDate, gameDate, restructuredToDate)

      if (restructuredFromDate <= gameDate && gameDate <= restructuredToDate) {
        console.log('date  is within the to-from range, so proceed in building the chart dataset')

        if (leagueFilter === "ALL") {
          LABELS.push(level)

          if (STAT === "Goals") {
            // set Average Player Stats
            DATASETS[0].data.push(DATA[i].homeTeamScore / 12)

            // set Top Player Stats
            DATASETS[2].data.push(DATA[i].homeTeamScore / 3)

            // set DATASETS unique player data
            DATASETS[1].data.push(DATA[i].goals)

            setColor(league, level)

          } else if (STAT === "Assists") {
            // set Average Player Stats
            DATASETS[0].data.push((DATA[i].homeTeamScore / 2) / 12)

            // set Top Player Stats
            DATASETS[2].data.push((DATA[i].homeTeamScore / 2) / 5)

            // set DATASETS unique player data
            DATASETS[1].data.push(DATA[i].playerAssists)

            setColor(league, level)

          }
        } else {
          if (leagueFilter === league || secondaryLeagueFilter === league) {

            LABELS.push(level)
            if (STAT === "Goals") {
              // set Average Player Stats
              DATASETS[0].data.push(DATA[i].homeTeamScore / 12)

              // set Top Player Stats
              DATASETS[2].data.push(DATA[i].homeTeamScore / 3)

              // set DATASETS unique player data
              DATASETS[1].data.push(DATA[i].goals)

              setColor(league, level)

            } else if (STAT === "Assists") {
              // set Average Player Stats
              DATASETS[0].data.push((DATA[i].homeTeamScore / 2) / 12)

              // set Top Player Stats
              DATASETS[2].data.push((DATA[i].homeTeamScore / 2) / 5)

              // set DATASETS unique player data
              DATASETS[1].data.push(DATA[i].playerAssists)

              setColor(league, level)

            }
          }
        }
      }

    }

    return {LABELS: LABELS, DATASETS: DATASETS}
  }

  filterChart = (leagueFilter, secondaryLeagueFilter) => {
    console.log('this.chart ', this.chart.data.datasets)

    console.log('leagueFilter = ', leagueFilter, secondaryLeagueFilter)
    let fromDate = this.state.fromDate
    let toDate   = this.state.toDate

    this.setState({currentLeagueFilter: leagueFilter})
    this.setState({currentSecondaryLeagueFilter: secondaryLeagueFilter})

    console.log(
      'fromDate = ', fromDate,
      'toDate = ', toDate,
      'leagueFilter = ', leagueFilter,
      'secondaryLeagueFilter = ', secondaryLeagueFilter
    )

    // destroy existing data display
    this.chart.data.datasets = []

    // clear color array
    backgroundColor = []

    // fetch only the data associated with the league
    let filteredDataset = this.buildDataset(leagueFilter, secondaryLeagueFilter, fromDate, toDate)
    console.log('filteredDataset ', filteredDataset.DATASETS)

    // push filtered data into chart
    this.chart.data.labels   = filteredDataset.LABELS
    this.chart.data.datasets = filteredDataset.DATASETS
    this.chart.update()
  }

  convertDate = (date) => {
    // original date format of the parameter "2022-11-17"
    // required date format is "yyyy-mm-dd"
    let yyyy = date.split('-')[0]
    let mm   = date.split('-')[1]
    let dd   = date.split('-')[2]
    return yyyy + '-' + mm + '-' + dd
  }

  updateDate = (e, id) => {
    console.log("e.target.value ", this.convertDate(e.target.value))
    let obj = {
      toDate: e.target.value
    }

    if (id === "fromDate") {
      obj = {
        fromDate: e.target.value
      }
    }
    this.setState(obj, () => {
      console.log('fromDate = ', this.state.fromDate, ' toDate = ', this.state.toDate)
      this.filterChart(this.state.currentLeagueFilter, this.state.currentSecondaryLeagueFilter)
    })
  }

  render() {
    return (
      <div className="charts">
        <small>Tap on league to filter:</small>
        <div className="legend">
          <span className="ALL" onClick={(e) => this.filterChart("ALL")}>ALL</span>
          <span className="OMGHA" onClick={(e) => this.filterChart("ASSOC", "OMGHA")}>ASSOC.</span>
          <span className="SDP" onClick={(e) => this.filterChart("AAA", "SDP")}>AAA</span>
          <span className="MNMADE" onClick={(e) => this.filterChart("MN MADE")}>MADE</span>
          <span className="OTHER" onClick={(e) => this.filterChart("OTHER")}>OTHER</span>
        </div>
        <div className={'mt-15 center_text'}>
          <input label={"From:"} type="date" name="from" min="2022-01"
                 onChange={(e) => this.updateDate(e, "fromDate")}
                 value={this.state.fromDate}/>
          <span className={'m10'}>—</span>
          <input label={"To:"} type="date" name="to" min="2022-02"
                 onChange={(e) => this.updateDate(e, "toDate")}
                 value={this.state.toDate}/>
        </div>
        <canvas ref={this.chartRef}/>
      </div>
    )
  }
}