import * as React from 'react';
import {Component} from 'react';
import {Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';

export default class KPIsGoalie extends Component {

  componentDidMount() {
    console.log('KPIs  COMPONENT >>>>>>>>>>> ', this.props.params);

  }

  render() {
    return (
      <div className={"overall_stats_table"}>
        <TableContainer>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Avg Save %</TableCell>
                <TableCell>GAA</TableCell>
                <TableCell>SAA</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{this.props.params[0]}%</TableCell>
                <TableCell>{this.props.params[1]}</TableCell>
                <TableCell>{this.props.params[2]}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}