import React, { Component }                      from 'react';
import {
	Button,
	IconButton,
	Menu,
	MenuItem,
	ListItemText,
}                                                from '@material-ui/core';
import {AccountCircle, BarChart as BarChartIcon} from '@material-ui/icons';
import { withOktaAuth }                          from '@okta/okta-react';
import {Link}                                    from "react-router-dom";
import Fab                                       from "@material-ui/core/Fab";

class LoginButton extends Component {
	state = {
		authenticated: null,
		user: null,
		menuAnchorEl: null,
	};

	componentDidUpdate() {
		this.checkAuthentication();
	}

	componentDidMount() {
		this.checkAuthentication();
	}

	async checkAuthentication() {
		const authenticated = this.props.authState.isAuthenticated;
		if (authenticated !== this.state.authenticated) {
			const user = await this.props.authService.getUser();
			// console.log('user ', user)
			this.setState({ authenticated, user });
		}
	}

	login = () => this.props.authService.login('/statz');
	logout = () => {
		this.handleMenuClose();
		this.props.authService.logout('/');
	};
	redirect = (page) => {
		window.location.href = page;
	};

	handleMenuOpen = event => this.setState({ menuAnchorEl: event.currentTarget });
	handleMenuClose = () => this.setState({ menuAnchorEl: null });

	render() {
		const { authenticated, user, menuAnchorEl } = this.state;

		if (authenticated === null) return null;
		if (!authenticated) return (<div>
			{/*<Button color="inherit" href={"https://blog.hockeystatz.com"} target={"_blank"}><u>BLOG</u></Button>*/}
			<Button color="inherit" onClick={this.login}><u>LOGIN</u></Button>
		</div>);

		const menuPosition = {
			vertical: 'top',
			horizontal: 'right',
		};

		return (
			<div>
				<Button color="inherit"
				        target={"_blank"}
				        href={"https://blog.hockeystatz.com"}><u>BLOG</u></Button>
				<Fab
					color="inherit"
					className="no_shadow"
					component={Link}
					to="/statz"
				>
					<BarChartIcon/>
				</Fab>
				<IconButton onClick={this.handleMenuOpen} color="inherit">
					<AccountCircle />
				</IconButton>
				<Menu
					anchorEl={menuAnchorEl}
					anchorOrigin={menuPosition}
					transformOrigin={menuPosition}
					open={!!menuAnchorEl}
					onClose={this.handleMenuClose}
				>
					<MenuItem onClick={this.logout}>
						<ListItemText
							primary="Logout"
							secondary={user && user.name}
						/>
					</MenuItem>
					<MenuItem onClick={this.handleMenuClose} component={Link} to="/profile">
						<ListItemText
							primary="Profile"
						/>
					</MenuItem>
					<MenuItem onClick={this.handleMenuClose} component={Link} to="/contact">
						<ListItemText
							primary="Contact Us"
						/>
					</MenuItem>
				</Menu>
			</div>
		);
	}
}

export default withOktaAuth(LoginButton);
