import * as React    from 'react';
import {
	Box, Button, FormControl, FormHelperText, InputLabel, MenuItem,
	Modal, Select,
	TextField
}                    from '@material-ui/core';
import axios         from "axios";
import '../global.css';
import ErrorSnackbar from "./ErrorSnackbar";

const style = {
	position : 'absolute',
	top      : '50%',
	left     : '50%',
	transform: 'translate(-50%, -50%)',
	width    : 400,
	bgcolor  : 'background.paper',
	border   : '2px solid #000',
	boxShadow: 24,
	p        : 4,
};


export default function AddGameModal(params) {

	console.log('AddGameModal ', params.params[0], params.params[1]);

	const SUB                                         = params.params[0];
	const PLAYER                                      = params.params[1];
	const [open, setOpen]                             = React.useState(false);
	const [date]                                      = React.useState(new Date().toLocaleString());
	const [leaguesApiResponse, setLeaguesApiResponse] = React.useState(null);
	const [league, setLeague]                         = React.useState("");
	const [leagues, setLeagues]                       = React.useState([]);
	const [selectedLeague, setSelectedLeague]         = React.useState([]);
	const [level, setLevel]                           = React.useState("");
	const [levels, setLevels]                         = React.useState([]);
	const [opponent, setOpponent]                     = React.useState("");
	const [team, setTeam]                             = React.useState("");
	const [teams, setTeams]                           = React.useState([]);
	const [error, setError]                           = React.useState(null);

	const handleOpen = () => {
		setOpen(true);
		fetchTeams();
	}


	const handleClose = () => {
		setOpen(false);
		setLeague("");
		setLeagues([]);
		setSelectedLeague([]);
		setLevel("");
		setLevels([]);
		setOpponent("");
		setTeam("");
		setTeams([]);
	}


	const url     = process.env.REACT_APP_ANYTHING_API;
	const KEY     = process.env.REACT_APP_ANYTHING_KEY;
	const headers = {
		'content-type': 'application/json',
		accept        : 'application/json',
		'x-api-key'   : `${KEY}`
	};

	const computeLeagues = (res) => {
		let all_leagues = [];

		for (let i = 0; i < res.length; i++) {
			console.log('League = ', res[i]);
			if (res[i].league === 'SDP' || res[i].league === 'OMGHA') {
				// do nothing
			} else {
				all_leagues.push(res[i].league);
			}
		}

		console.log('all_leagues = ', all_leagues);
		setLeagues(all_leagues);

		getLevelsInSelectedLeague();
	}

	const getLevelsInSelectedLeague = (league) => {
		let levels_in_selected_league = [];

		console.log('league data = ', leaguesApiResponse);
		for (let i = 0; i < leaguesApiResponse.length; i++) {
			if (leaguesApiResponse[i].league === league) {
				console.log('league matched so grab all associated levels ', leaguesApiResponse[i].levels);

				setSelectedLeague(leaguesApiResponse[i].levels);

				let league_levels = leaguesApiResponse[i].levels;
				for (let ii = 0; ii < league_levels.length; ii++) {
					levels_in_selected_league.push(league_levels[ii].level);
				}
			}
		}

		setLevels(levels_in_selected_league);
	}

	const getTeamsInSelectedLevel = (level) => {
		let teams_in_selected_level = [];

		console.log('level data = ', level, " leagueData = ", selectedLeague);
		for (let i = 0; i < selectedLeague.length; i++) {
			if (selectedLeague[i].level === level) {
				console.log('level matched so grab all associated teams ', selectedLeague[i].teams);

				let level_teams = selectedLeague[i].teams;
				for (let ii = 0; ii < level_teams.length; ii++) {
					teams_in_selected_level.push(level_teams[ii]);
				}
			}
		}
		console.log('teams_in_selected_level = ', teams_in_selected_level)
		setTeams(teams_in_selected_level);
	}

	const fetchTeams = () => {
		console.log('fetchTeams');

		const url     = process.env.REACT_APP_ANYTHING_API;
		const KEY     = process.env.REACT_APP_ANYTHING_KEY;
		const headers = {
			'content-type': 'application/json',
			accept        : 'application/json',
			'x-api-key'   : `${KEY}`
		};

		axios.get(url + `?TableName=leagues`, {headers})
			 .then(response => {
				 console.log('get leagues response from table ', response);

				 setLeaguesApiResponse(response.data.Items);

				 computeLeagues(response.data.Items);
			 })
			 .catch(error => {
				 console.warn("getPosts error::: ", error);
			 })
	}

	const getDate = () => {
		console.log(date);
		return date;
	}

	const createNewGameRecord = () => {
		console.log('SAVE>....', league, ' ', SUB, ' ', PLAYER, ' ', team);

		if (league.length === 0 ||
			level.length === 0 ||
			team.length === 0 ||
			opponent.length === 0) {
			setError('Missing required fields');
		} else {
			const req_date = getDate();
			let req        = {
				"Item"     : {
					"league"        : league,
					"level"         : level,
					"date"          : req_date,
					"account"       : SUB,
					"name"          : PLAYER,
					"goals"         : 0,
					"playerAssists" : 0,
					"team"          : team,
					"shotsAgainst"  : 0,
					"goalsAgainst"  : 0,
					"saves"         : 0,
					"savePercentage": 0,
					"playerPlusMinus"     : 0,
					"playerShots"     : 0,
					"opponent"      : opponent
				},
				"TableName": PLAYER
			}
			console.log('createNewGame request object = ', req);
			axios.post(url, req, {headers})
				 .then(response => {
					 console.log(' response from table ', response);
					 window.location.reload();
				 })
				 .catch(error => {
					 console.warn("error::: ", error);
				 })
		}
	}

	function handleChange(val, field) {
		// console.log('handleChange field=', e);
		console.log('handleChange field=', field, ' //// value=', val)
		if (field === "league") {
			setLeague(val);
			getLevelsInSelectedLeague(val);
		}

		if (field === "level") {
			setLevel(val);
			getTeamsInSelectedLevel(val);
		}

		if (field === "team") {
			setTeam(val);
		}

		if (field === "opponent") {
			setOpponent(val);
		}
	}

	return (
		<div className="add_game_modal">
			<Button variant="contained" id={"add-new-game"} data-test={"add_new_game"} onClick={handleOpen}>
				Add New Game
			</Button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className="custom_modal_styles"
			>
				<Box sx={style}>
					<Button size="small" color="secondary" variant="outlined" onClick={handleClose}>
						Close
					</Button>
					<h4>Add New Game for {PLAYER}</h4>
					<FormControl fullWidth>
						<InputLabel id="league-select-label" data-test={"League"}>League <sup>*</sup></InputLabel>
						<Select
							labelId="league-select-label"
							id="league"
							value={league}
							label="League"
							onChange={(e) => handleChange(e.target.value, "league")}
						>
							{leagues.map((item, i) => (
								<MenuItem key={i} value={item} data-test={"League"+i}>{item}</MenuItem>
							))}
						</Select>
						<FormHelperText>Required</FormHelperText>
					</FormControl>
					<br/>
					<br/>
					<FormControl fullWidth>
						<InputLabel id="level-select-label" data-test="Level">Level <sup>*</sup></InputLabel>
						<Select
							labelId="level-select-label"
							id="level"
							value={level}
							label="Level"
							onChange={(e) => handleChange(e.target.value, "level")}
						>
							{levels.map((item, i) => (
								<MenuItem key={i} value={item} data-test={"Level"+i}>{item}</MenuItem>
							))}
						</Select>
						<FormHelperText>Required</FormHelperText>
					</FormControl>
					<br/>
					<br/>
					<FormControl fullWidth>
						<InputLabel id="team-select-label" data-test="Team">Team <sup>*</sup></InputLabel>
						<Select
							labelId="team-select-label"
							id="team"
							value={team}
							label="Team"
							onChange={(e) => handleChange(e.target.value, "team")}
						>
							{teams.map((item, i) => (
								<MenuItem key={i} value={item} data-test={"Team"+i}>{item}</MenuItem>
							))}
						</Select>
						<FormHelperText>Required</FormHelperText>
					</FormControl>

					<br/>
					<br/>
					<TextField id="opponent" label="Opponent Team Name *" variant="outlined"
							   onChange={(e) => handleChange(e.target.value, "opponent")}/>
					<p className="MuiFormHelperText-root">Required</p>
					<br/>
					<br/>
					<Button className={"SaveGame"}
							variant="contained"
							onClick={createNewGameRecord}>Save</Button>
				</Box>
			</Modal>

			<br/>
			<br/>
			{error && (
				<ErrorSnackbar
					onClose={() => setError(null)}
					message={error}
				/>
			)}
		</div>
	);
}
