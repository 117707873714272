import * as React                                                  from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@material-ui/core";


export default function FormsAccordion() {
	return (
		<div className="pt-5">
			<Accordion>
				<AccordionSummary
					expandIcon={'+'}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography>Technical Support</Typography>
				</AccordionSummary>
				<AccordionDetails className={"contact_form"}>
					<iframe
						title="Help"
						src='https://forms.zohopublic.com/hockeystatz/form/Help/formperma/vUfExnCoBZud_w6yrdk_V4ImMkpUaGYtpOqozjAUh8I'></iframe>
				</AccordionDetails>
			</Accordion>
			<Accordion>
				<AccordionSummary
					expandIcon={'+'}
					aria-controls="panel2a-content"
					id="panel2a-header"
				>
					<Typography>Have Ideas For A New Feature?</Typography>
				</AccordionSummary>
				<AccordionDetails className={"contact_form"}>
					<Typography>
						<iframe
							title="Feature Request"
							src='https://forms.zohopublic.com/hockeystatz/form/FeatureRequest/formperma/WTSS5lW8fS59GYc0I6MZHcNDoHfoeNNX2MQ2B4wdrII'></iframe>
					</Typography>
				</AccordionDetails>
			</Accordion>

		</div>
	);
}
