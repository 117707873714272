import React, {Component, Fragment} from 'react';
import {withOktaAuth}               from '@okta/okta-react';
import {withRouter}                 from 'react-router-dom';
import {
	Typography,
	withStyles
} from '@material-ui/core';
import {compose}                    from 'recompose';
import ErrorSnackbar                from '../components/ErrorSnackbar';
import FormsAccordion               from "../components/FormsAccordion";


const styles = theme => ({
	posts: {
		marginTop: theme.spacing(2),
	},
	fab  : {
		position                      : 'absolute',
		bottom                        : theme.spacing(3),
		right                         : theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			bottom: theme.spacing(2),
			right : theme.spacing(2),
		},
	},
});

class ContactUs extends Component {
	state = {
		loading: true,
		posts  : {},
		error  : null,
		name   : "",
		user   : null,
		email  : null,
		sub    : null,
		tables : []
	};

	componentDidMount() {
		this.checkAuthentication();
	}

	async checkAuthentication() {
		const authenticated = this.props.authState.isAuthenticated;
		if (authenticated !== this.state.authenticated) {
			const user = await this.props.authService.getUser();
			// console.log('>>>>>>user ', user, user.email)
			this.setState({email: user.email})
			this.setState({sub: user.sub})
		}
	}

	render() {
		return (
			<Fragment>
				<div className={"profile_pages_top"}></div>
				<div className="m-5-p">
					<Typography variant="h6">Contact Us</Typography>
					<FormsAccordion/>
				</div>
				{this.state.error && (
					<ErrorSnackbar
						onClose={() => this.setState({error: null})}
						message={this.state.error.message}
					/>
				)}
			</Fragment>
		);
	}
}

export default compose(
	withOktaAuth,
	withRouter,
	withStyles(styles),
)(ContactUs);
