import React, {Component, Fragment} from 'react';
import {withOktaAuth} from '@okta/okta-react';
import {withRouter} from 'react-router-dom';
import {
  Typography,
  withStyles
} from '@material-ui/core';
import {compose} from 'recompose';
import ErrorSnackbar from '../components/ErrorSnackbar';


const styles = theme => ({
  posts: {
    marginTop: theme.spacing(2)
  },
  fab  : {
    position                      : 'absolute',
    bottom                        : theme.spacing(3),
    right                         : theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      bottom: theme.spacing(2),
      right : theme.spacing(2)
    }
  }
});

class Profile extends Component {
  state = {
    loading: true,
    posts  : {},
    error  : null,
    name   : "",
    user   : null,
    email  : null,
    sub    : null,
    tables : []
  };

  componentDidMount() {
    this.checkAuthentication();
  }

  async checkAuthentication() {
    const authenticated = this.props.authState.isAuthenticated;
    if (authenticated !== this.state.authenticated) {
      const user = await this.props.authService.getUser();
      console.log('>>>>>>user ', user, user.email);
      this.setState({email: user.email});
      this.setState({name: user.name});
      this.setState({sub: user.sub});
      this.setState({loading: false});
    }
  }

  render() {
    if (this.state.loading) {
      return <div className="loading">Loading...</div>;
    } else {
      return (
        <Fragment>
          <div className={"profile_pages_top"}></div>
          <div className="m-5-p">
            <Typography variant="h6">Profile</Typography>
            <ul className="pt-5">
              <li>Name: {this.state.name}</li>
              <li>Email: {this.state.email}</li>
              <li><a href={'https://billing.stripe.com/p/login/dR62bVfjG6Ok3io3cc'}>Manage Subscription</a></li>
            </ul>
            <p>Please use the form below to contact us about modifying your profile.</p>
            <iframe
              title="Request Account Modification"
              src="https://forms.zohopublic.com/hockeystatz/form/RequestAccountModification/formperma/mZFgs0bqDYRXOER4uDfGrW1u0shkJe_S1mbbvu2XK1I"></iframe>
          </div>
          {this.state.error && (
            <ErrorSnackbar
              onClose={() => this.setState({error: null})}
              message={this.state.error.message}
            />
          )}
        </Fragment>
      );
    }
  }
}

export default compose(
  withOktaAuth,
  withRouter,
  withStyles(styles)
)(Profile);
