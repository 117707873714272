import * as React from 'react';
import KPIsGoalie from "./KPIsGoalie";
import Chart from 'chart.js/auto';

let savePercentageChartRef = {};
let goalsAgainstChartRef = React.createRef();
let shotsAgainstChartRef = React.createRef();

export default function GoalieMetrics(apiResponse) {
  console.log('Goalie Metrics apiResponse = ', apiResponse);
  const [filteredApiResponse, setFilteredApiResponse] = React.useState();
  const [averageSavePercentage, setAverageSavePercentage] = React.useState();
  const [averageGoalsAgainst, setAverageGoalsAgainst]     = React.useState();
  const [averageShotsAgainst, setAverageShotsAgainst]            = React.useState();
  const [fromDate, setFromDate]     = React.useState("2023-04-01");
  const [toDate, setToDate]     = React.useState(generateDate());
  const [currentLeagueFilter, setCurrentLeagueFilter]     = React.useState("ALL");
  const [currentSecondaryLeagueFilter, setCurrentSecondaryLeagueFilter]     = React.useState(undefined);

  let COLOR           = 'rgba(182, 15, 148, 0.69)'
  let backgroundColor = []

  setTimeout(function () {
    computeAverages(filterAPIresponseFN());
    console.log('savePercentageChartRef ', savePercentageChartRef);
  }, 100);

  function filterAPIresponseFN() {
    console.log('filteredApiResponse = ', filteredApiResponse)
    if (filteredApiResponse == undefined) {
      let resObj = updateDate(fromDate, "fromDate")
      return apiResponse[0]
    } else {
      return filteredApiResponse
    }
  }

  function computeAverages(gameArray) {
    let _averageSavePercentages = 0;
    let _averageGoalsAgainst    = 0;
    let _averageShotsAgainst    = 0;
    let dataArray               = gameArray;
    // console.log('>>>>>>> dataArray >>>>>>>> ', dataArray);

    for (let i = 0; i < dataArray.length; i++) {
      let iteration = dataArray[i];
      // console.log(iteration);
      _averageSavePercentages = _averageSavePercentages += iteration.savePercentage;
      _averageGoalsAgainst =  _averageGoalsAgainst += iteration.goalsAgainst;
      _averageShotsAgainst =  _averageShotsAgainst += iteration.shotsAgainst;
    }

    // console.log(`The sum is: ${_averageSavePercentages}. The average is: ${_averageSavePercentages / dataArray.length}.`);
    // console.log(`The sum is: ${_averageGoalsAgainst}. The average is: ${_averageGoalsAgainst / dataArray.length}.`);
    // console.log(`The sum is: ${_averageShotsAgainst}. The average is: ${_averageShotsAgainst / dataArray.length}.`);
    setAverageSavePercentage(parseFloat(_averageSavePercentages / dataArray.length).toFixed(2));
    setAverageGoalsAgainst(parseFloat(_averageGoalsAgainst / dataArray.length).toFixed(1));
    setAverageShotsAgainst(parseFloat(_averageShotsAgainst / dataArray.length).toFixed(0));

  }

  function setColor(league, level) {
    if (league === 'OMGHA') {
      COLOR = 'rgba(234,112,13,0.69)'
    } else if (league === 'ASSOC') {
      COLOR = 'rgba(234,112,13,0.69)'
    } else if (league === 'SDP') {
      COLOR = 'rgba(25,158,229,0.69)'
    } else if (league === 'AAA') {
      COLOR = 'rgba(25,158,229,0.69)'
    } else if (league === 'MN MADE') {
      COLOR = 'rgba(33,30,92, 0.69)'
    } else if (league === 'HockeyFinder') {
      if (level === '1.0') {
        COLOR = 'rgba(153,182,78,0.19)'
      } else if (level === '1.5') {
        COLOR = 'rgba(153,182,78,0.69)'
      } else if (level === '2.0') {
        COLOR = 'rgba(153,182,78,0.69)'
      } else if (level === '2.5') {
        COLOR = 'rgba(153,182,78,0.69)'
      } else if (level === '3.0') {
        COLOR = 'rgba(153,182,78,0.69)'
      } else if (level === '3.5') {
        COLOR = 'rgba(153,182,78,0.69)'
      } else if (level === '4.0') {
        COLOR = 'rgba(153,182,78,0.69)'
      } else if (level === '4.5') {
        COLOR = 'rgba(153,182,78,0.69)'
      }
    } else {
      // OTHER
      COLOR = 'rgba(137,17,164,0.69)'
    }
    backgroundColor.push(COLOR)
  }

  function generateDate() {
    let mmddyyyy = new Date().toLocaleString().split(",")[0].replace('/', '-').replace('/', '-')
    let yyyy     = mmddyyyy.split('-')[2]
    let mm       = mmddyyyy.split('-')[0]
    let dd       = mmddyyyy.split('-')[1]
    console.log(yyyy + '-' + mm + '-' + dd)
    return yyyy + '-' + mm + '-' + dd
  }

  function buildDataset(leagueFilter, secondaryLeagueFilter, _fromDate, _toDate) {
    console.log(';;;;;;;;;; buildDataset ;;;;;;;;;;; apiResponse ', apiResponse[0])
    let STAT             = "temp"
    let NAME = apiResponse[0][0].name !== undefined ? apiResponse[0][0].name : "This Player"
    let DATA             = apiResponse[0].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    let LABELS   = []
    let DATASETS         = [{
      type           : 'bar',
      data           : [],
      label          : `${NAME}'s`,
      xAxisID        : 'xAxis1',
      backgroundColor: backgroundColor,
      fill           : false,
      borderWidth    : 1
    }];
    let SHOTS_AGAINST_DATASET         = [{
      type           : 'bar',
      data           : [],
      label          : `${NAME}'s`,
      xAxisID        : 'xAxis1',
      backgroundColor: backgroundColor,
      fill           : false,
      borderWidth    : 1
    }];
    let GOAL_AGAINST_DATASET         = [{
      type           : 'bar',
      data           : [],
      label          : `${NAME}'s`,
      xAxisID        : 'xAxis1',
      backgroundColor: backgroundColor,
      fill           : false,
      borderWidth    : 1
    }];
    let gamesInDateRange = [];

    for (let i = 0; i < DATA.length; i++) {
      // set LABELS
      // this date format is  mm/dd/yyyy, HH[AM,PM]
      let GAME = DATA[i];
      let GAME_savePercentage = GAME.savePercentage;
      let GAME_shotsAgainst = GAME.shotsAgainst;
      let GAME_goalsAgainst = GAME.goalsAgainst;
      let date   = DATA[i].date.split(/:[0-9][0-9]/)[0] + DATA[i].date.split(/[0-9] /)[1]
      let gameDate = new Date(date.split(',')[0])
      let league = DATA[i].league
      let level = DATA[i].level

      console.log(
        'fromDate = ', fromDate,
        'toDate = ', toDate
      )

      let _fromDateRev = _fromDate.split('-').reverse()
      let _toDateRev = _toDate.split('-').reverse()
      // mm/dd/yyyy
      let restructuredFromDate = new Date(_fromDateRev[1] + '/' + _fromDateRev[0] + '/' + _fromDateRev[2])
      let restructuredToDate = new Date(_toDateRev[1] + '/' + _toDateRev[0] + '/' + _toDateRev[2])

      console.log(restructuredFromDate, gameDate, restructuredToDate)

      if (restructuredFromDate <= gameDate && gameDate <= restructuredToDate) {
        console.log('date  is within the to-from range, so proceed in building the chart dataset');

        if (leagueFilter === "ALL") {

          gamesInDateRange.push(apiResponse[0][i])

          LABELS.push(DATA[i].date.split(",")[0])
          SHOTS_AGAINST_DATASET[0].data.push(GAME_shotsAgainst)
          GOAL_AGAINST_DATASET[0].data.push(GAME_goalsAgainst)

          if (typeof (GAME_savePercentage) === 'string') {
            DATASETS[0].data.push(GAME_savePercentage.split('%')[0])
          } else {
            DATASETS[0].data.push(GAME_savePercentage)
          }

          setColor(league, level)

          // if (STAT === "Shots Against") {
          //   // set DATASETS unique player data
          //   DATASETS[0].data.push(DATA[i].shotsAgainst)
          //
          //   setColor(league, level)
          //
          // } else if (STAT === "Goals Against") {
          //   // set DATASETS unique player data
          //   DATASETS[1].data.push(DATA[i].goalsAgainst)
          //
          //   setColor(league, level)
          //
          // } else if (STAT === "Save Percentage") {
          //   // set DATASETS unique player data
          //
          //   if (typeof (DATA[i].savePercentage) === 'string') {
          //     DATASETS[2].data.push(DATA[i].savePercentage.split('%')[0])
          //   } else {
          //     DATASETS[2].data.push(DATA[i].savePercentage)
          //   }
          //
          //   setColor(league, level)
          //
          // }
        } else {
          if (leagueFilter === league || secondaryLeagueFilter === league) {

            gamesInDateRange.push(apiResponse[0][i])

            LABELS.push(DATA[i].date.split(",")[0])
            SHOTS_AGAINST_DATASET[0].data.push(GAME_shotsAgainst)
            GOAL_AGAINST_DATASET[0].data.push(GAME_goalsAgainst)

            if (typeof (GAME_savePercentage) === 'string') {
              DATASETS[0].data.push(GAME_savePercentage.split('%')[0])
            } else {
              DATASETS[0].data.push(GAME_savePercentage)
            }

            setColor(league, level)

            // if (STAT === "Shots Against") {
            //   // set DATASETS unique player data
            //   DATASETS[0].data.push(DATA[i].shotsAgainst)
            //
            //   setColor(league, level)
            //
            // } else if (STAT === "Goals Against") {
            //   // set DATASETS unique player data
            //   DATASETS[1].data.push(DATA[i].goalsAgainst)
            //
            //   setColor(league, level)
            //
            // } else if (STAT === "Save Percentage") {
            //   // set DATASETS unique player data
            //
            //   if (typeof (DATA[i].savePercentage) === 'string') {
            //     DATASETS[2].data.push(DATA[i].savePercentage.split('%')[0])
            //   } else {
            //     DATASETS[2].data.push(DATA[i].savePercentage)
            //   }
            //
            //   setColor(league, level)
            //
            // }
          }
        }

      } else {
        console.log('gameDate is outside filter range at ', gameDate)
      }

    }
    console.log('gamesInDateRange ======= ', gamesInDateRange)

    setFilteredApiResponse(gamesInDateRange)

    return {LABELS: LABELS, DATASETS: DATASETS, SHOTS_AGAINST_DATASET:SHOTS_AGAINST_DATASET, GOAL_AGAINST_DATASET:GOAL_AGAINST_DATASET}
  }

  function filterChart(leagueFilter, secondaryLeagueFilter, _fromDate, _toDate) {
    // console.log('this.chart ', this.chart.data.datasets)

    console.log('leagueFilter = ', leagueFilter, secondaryLeagueFilter)

    setCurrentLeagueFilter(leagueFilter)
    setCurrentSecondaryLeagueFilter(secondaryLeagueFilter)

    console.log(
      'fromDate = ', fromDate,
      'toDate = ', toDate,
      'leagueFilter = ', leagueFilter,
      'secondaryLeagueFilter = ', secondaryLeagueFilter
    )

    // destroy existing data display
    // setChart([])

    // clear color array
    // backgroundColor = []

    // fetch only the data associated with the league
    let filteredDataset = buildDataset(leagueFilter, secondaryLeagueFilter, _fromDate, _toDate)
    console.log('filteredDataset ', filteredDataset.DATASETS)

    // push filtered data into chart
    // this.chart.data.labels   = filteredDataset.LABELS
    // this.chart.data.datasets = filteredDataset.DATASETS
    // this.chart.update()

    try {

      savePercentageChartRef = new Chart(document.getElementById('save_percentage'), {
        data   : {
          labels  : filteredDataset.LABELS,
          datasets: filteredDataset.DATASETS
        },
        options: {
          plugins    : {
            legend: false
          },
          transitions: {
            show: {
              animations: {
                x: {
                  from: 0
                },
                y: {
                  from: 0
                }
              }
            },
            hide: {
              animations: {
                x: {
                  to: 0
                },
                y: {
                  to: 0
                }
              }
            }
          }
        }
      })

      goalsAgainstChartRef = new Chart(document.getElementById('goals_against'), {
        data   : {
          labels  : filteredDataset.LABELS,
          datasets: filteredDataset.GOAL_AGAINST_DATASET
        },
        options: {
          plugins    : {
            legend: false
          },
          transitions: {
            show: {
              animations: {
                x: {
                  from: 0
                },
                y: {
                  from: 0
                }
              }
            },
            hide: {
              animations: {
                x: {
                  to: 0
                },
                y: {
                  to: 0
                }
              }
            }
          }
        }
      })

      shotsAgainstChartRef = new Chart(document.getElementById('shots_against'), {
        data   : {
          labels  : filteredDataset.LABELS,
          datasets: filteredDataset.SHOTS_AGAINST_DATASET
        },
        options: {
          plugins    : {
            legend: false
          },
          transitions: {
            show: {
              animations: {
                x: {
                  from: 0
                },
                y: {
                  from: 0
                }
              }
            },
            hide: {
              animations: {
                x: {
                  to: 0
                },
                y: {
                  to: 0
                }
              }
            }
          }
        }
      })

    } catch (e) {
      console.log(savePercentageChartRef, ' chart already exists ', e)

      savePercentageChartRef.data.labels   = filteredDataset.LABELS;
      savePercentageChartRef.data.datasets = filteredDataset.DATASETS;
      savePercentageChartRef.update();

      goalsAgainstChartRef.data.labels   = filteredDataset.LABELS;
      goalsAgainstChartRef.data.datasets = filteredDataset.GOAL_AGAINST_DATASET;
      goalsAgainstChartRef.update();

      shotsAgainstChartRef.data.labels   = filteredDataset.LABELS;
      shotsAgainstChartRef.data.datasets = filteredDataset.SHOTS_AGAINST_DATASET;
      shotsAgainstChartRef.update();
    }

    return filteredDataset;
  }

  function updateDate(e, id) {

    let convertedDate = null;

    if (typeof (e) === 'string') {
      console.log(`updateDate ${id} of [fromDate=${fromDate} and toDate=${toDate}] to ${e} `, convertDate(e))
      convertedDate = convertDate(e);
    } else {
      console.log(`updateDate ${id} of [fromDate=${fromDate} and toDate=${toDate}] to ${e.target.value} `, convertDate(e.target.value))
      convertedDate = convertDate(e.target.value);
    }


    if (id === "fromDate") {
      setFromDate(convertedDate)
      return filterChart(currentLeagueFilter, currentSecondaryLeagueFilter, convertedDate, toDate)
    } else {
      setToDate(convertedDate)
      return filterChart(currentLeagueFilter, currentSecondaryLeagueFilter, fromDate, convertedDate)
    }
  }

  function convertDate(date){
    // original date format of the parameter "2022-11-17"
    // required date format is "yyyy-mm-dd"
    let yyyy = date.split('-')[0]
    let mm   = date.split('-')[1]
    let dd   = date.split('-')[2]
    return yyyy + '-' + mm + '-' + dd
  }


    return (
      <div className={"metrics_accordion"}>

        <div className={'mt-15 center_text'}>
          <input label={"From:"} type="date" name="from" min="2022-01-01"
                 onChange={(e) => updateDate(e, "fromDate")}
                 value={fromDate}/>
          <span className={'m10'}>—</span>
          <input label={"To:"} type="date" name="to" min="2022-02-02"
                 onChange={(e) => updateDate(e, "toDate")}
                 value={toDate}/>
        </div>

        <h5 className={'center_text'}>Tap on league to filter:</h5>
        <div className="legend">
          <span className="ALL" onClick={(e) => filterChart("ALL", null, fromDate, toDate)}>ALL</span>
          <span className="OMGHA" onClick={(e) => filterChart("ASSOC", "OMGHA", fromDate, toDate)}>ASSOC.</span>
          <span className="SDP" onClick={(e) => filterChart("AAA", "SDP",  fromDate, toDate)}>AAA</span>
          <span className="MNMADE" onClick={(e) => filterChart("MN MADE", null, fromDate, toDate)}>MADE</span>
          <span className="HF" onClick={(e) => filterChart("HockeyFinder", null, fromDate, toDate)}>HF</span>
          <span className="OTHER" onClick={(e) => filterChart("OTHER", null, fromDate, toDate)}>OTHER</span>
        </div>

        <KPIsGoalie params={[averageSavePercentage, averageGoalsAgainst, averageShotsAgainst]}/>

        <h2>Save Percentage Trends:</h2>
        <canvas id="save_percentage"></canvas>

        <h2>Goals Against Trends:</h2>
        <canvas id="goals_against"></canvas>

        <h2>Shots Against Trends:</h2>
        <canvas id="shots_against"></canvas>
      </div>
    );

}
