import React, {Component, Fragment} from 'react';
import {withOktaAuth}               from '@okta/okta-react';
import {withRouter}                 from 'react-router-dom';
import {
	withStyles
}                                   from '@material-ui/core';
import {compose}                    from 'recompose';
import ErrorSnackbar                from '../components/ErrorSnackbar';
import DemoRenderResult            from "../functions/DemoRenderResult";


const styles = theme => ({
	posts: {
		marginTop: theme.spacing(2),
	},
	fab  : {
		position                      : 'absolute',
		bottom                        : theme.spacing(3),
		right                         : theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			bottom: theme.spacing(2),
			right : theme.spacing(2),
		},
	},
});

class PostsManager extends Component {
	state = {
		loading: true,
		posts  : {},
		error  : null,
		name   : "",
		user   : null,
		email  : null,
		sub    : null,
		tables : []
	};

	componentDidMount() {
		this.getPosts();
	}

	async getPosts() {
		let demo_obj = [{"name":"Alex_Dahl","position":"GOALIE"},{"name":"Ben_Dahl","position":"SKATER"}];
		this.setState({tables: demo_obj})
		this.setState({loading: false});
	}

	render() {
		// const {classes} = this.props;
		if (this.state.loading) {
			return <div className="loading">Loading...</div>
		} else {
			return (
				<Fragment>
					<DemoRenderResult params={[this.state]}/>
					{this.state.error && (
						<ErrorSnackbar
							onClose={() => this.setState({error: null})}
							message={this.state.error.message}
						/>
					)}
				</Fragment>
			);
		}
	}
}

export default compose(
	withOktaAuth,
	withRouter,
	withStyles(styles),
)(PostsManager);
