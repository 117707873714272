import * as React from 'react';
import {
	Box,
	Modal,
	TextField,
	span,
	Grid,
	Button,
}                 from '@material-ui/core';
import {
	RemoveCircleRounded,
	AddCircle,
	Edit
}                 from '@material-ui/icons';
import axios      from "axios";

const style = {
	position : 'absolute',
	top      : '50%',
	left     : '50%',
	transform: 'translate(-50%, -50%)',
	width    : 400,
	bgcolor  : 'background.paper',
	border   : '2px solid #000',
	boxShadow: 24,
	p        : 4,
};

export default function UpdateRecordModal(item) {
	console.log('UpdateRecordModal ', item);

	const Item            = item.params[0];
	const playerTable     = item.params[1];
	const playerPosition  = item.params[2];
	const url             = process.env.REACT_APP_ANYTHING_API;
	const KEY             = process.env.REACT_APP_ANYTHING_KEY;
	const headers         = {
		'content-type': 'application/json',
		accept        : 'application/json',
		'x-api-key'   : `${KEY}`
	};
	const [open, setOpen]                     = React.useState(false);
	const [goals, setGoals]                   = React.useState(Item.goals);
	const [homeTeamScore, setHomeTeamScore]   = React.useState(Item.homeTeamScore = (Item.homeTeamScore === undefined) ? 0 : Item.homeTeamScore)
	const [awayTeamScore, setAwayTeamScore]   = React.useState(Item.awayTeamScore = (Item.awayTeamScore === undefined) ? 0 : Item.awayTeamScore)
	const [shotsAgainst, setShotsAgainst]     = React.useState(Item.shotsAgainst = (Item.shotsAgainst === undefined) ? 0 : Item.shotsAgainst)
	const [goalsAgainst, setGoalsAgainst]     = React.useState(Item.goalsAgainst = (Item.goalsAgainst === undefined) ? 0 : Item.goalsAgainst)
	const [saves, setSaves]                   = React.useState(Item.saves = (Item.saves === undefined) ? 0 : Item.saves)
	const [savePercentage, setSavePercentage] = React.useState(Item.savePercentage = (Item.savePercentage === undefined) ? 0 : Item.savePercentage)
	const [playerAssists, setPlayerAssists]   = React.useState(Item.playerAssists = (Item.playerAssists === undefined) ? 0 : Item.playerAssists)
	const [playerShots, setPlayerShots]   = React.useState(Item.playerShots = (Item.playerShots === undefined) ? 0 : Item.playerShots)
	const [playerPlusMinus, setPlayerPlusMinus]   = React.useState(Item.playerPlusMinus = (Item.playerPlusMinus === undefined) ? 0 : Item.playerPlusMinus)

	const postData = () => {
		let req = {
			"Item"     : Item,
			"TableName": playerTable
		}
		axios.post(url, req, {headers})
			 .then(response => {
				 console.log(' response from table ', response);
				 // window.location.reload();
			 })
			 .catch(error => {
				 console.warn("error::: ", error);
				 // window.location.reload();
			 })
	}

	/**
	 * Returns "value" as the expectedType, if type does not match expectedType
	 * @param value
	 * @param expectedType
	 */
	const enforceType = (value, expectedType) => {
		console.log('value ' + value + ', expectedType = ' + expectedType + ' actualType = ' + typeof (value));
		if (expectedType === 'string' && typeof (value) !== 'string') {
			// expected string but found something else, so cast the value to a string
			return value.toString();
		} else if (expectedType === 'number' && typeof (value) !== 'number') {
			// expected number but found something else, so cast the value to a number
			return parseInt(value);
		} else if (expectedType === 'float') {
			// expected float but found something else, so cast the value to a float (which becomes type = number)
			if (typeof (value) === 'number' && value <= 1) {
				// multiple by 100 to move the decimal
				return parseFloat((value * 100).toFixed(2));
			} else {
				return parseFloat(value.toFixed(2));
			}
		} else {
			return value;
		}
	}

	/*
	 * This is the last block called in the stack, also used for saving data
	 */
	const SetSavePercentage = (value) => {
		console.log('start SetSavePercentage ', new Date());

		if (value !== undefined) {
			let _savePercentage = enforceType(value, 'float');
			Item.savePercentage = _savePercentage;
			setSavePercentage(_savePercentage);
		}

		postData();

		console.log('end SetSavePercentage ', new Date());
	}

	const SetGoals = (value) => {
		console.log('start SetGoals ', new Date());
		let _goals = enforceType(value, 'number');
		Item.goals = _goals;
		setGoals(_goals);
		console.log('end SetGoals ', new Date());
		postData();
	}

	const SetPlayerAssists = (value) => {
		console.log('start SetPlayerAssists ', new Date());
		let _playerAssists = enforceType(value, 'number');
		Item.playerAssists = _playerAssists;
		setPlayerAssists(_playerAssists);
		console.log('end SetPlayerAssists ', new Date());
	}

	const SetPlayerPlusMinus = (value) => {
		console.log('start SetPlayerAssists ', new Date());
		let _playerPlusMinus = enforceType(value, 'number');
		Item.playerPlusMinus = _playerPlusMinus;
		setPlayerPlusMinus(_playerPlusMinus);
		console.log('end setPlayerPlusMinus ', new Date());
	}

	const SetPlayerShots = (value) => {
		console.log('start SetPlayerShots ', new Date());
		let _playerShots = enforceType(value, 'number');
		Item.playerShots = _playerShots;
		setPlayerShots(_playerShots);
		console.log('end SetPlayerShots ', new Date());
	}

	const SetHomeTeamScore = (value) => {
		console.log('start SetHomeTeamScore ', new Date());
		let _homeTeamScore = enforceType(value, 'number');
		Item.homeTeamScore = _homeTeamScore;
		setHomeTeamScore(_homeTeamScore);
		console.log('end SetHomeTeamScore ', new Date());
	}

	const SetAwayTeamScore = (value) => {
		console.log('start SetAwayTeamScore ', new Date());
		let _awayTeamScore = enforceType(value, 'number');
		Item.awayTeamScore = _awayTeamScore;
		setAwayTeamScore(_awayTeamScore);
		console.log('end SetAwayTeamScore ', new Date());
	}

	const SetGoalsAgainst = (value) => {
		console.log('start SetGoalsAgainst ', new Date());
		let _goalsAgainst = enforceType(value, 'number');
		Item.goalsAgainst = _goalsAgainst;
		setGoalsAgainst(_goalsAgainst);
		console.log('end SetGoalsAgainst ', new Date());
	}

	const SetShotsAgainst = (value) => {
		console.log('start SetShotsAgainst ', new Date());
		let _shotsAgainst = enforceType(value, 'number');
		Item.shotsAgainst = _shotsAgainst;
		setShotsAgainst(_shotsAgainst);
		console.log('end SetShotsAgainst ', new Date());
	}

	const SetSaves = (value) => {
		console.log('start SetSaves ', new Date());
		let _saves = enforceType(value, 'number');
		Item.saves = _saves;
		setSaves(_saves);
		console.log('end SetSaves ', new Date());
	}

	const handleOpen = () => {
		setOpen(true);
	}

	const handleClose = () => {
		setOpen(false);
		window.location.reload();
	}

	const updateGoals = (operation) => {
		let current_goals_count = enforceType(Item.goals, 'number');

		if (operation === '+') {
			SetGoals(current_goals_count + 1);
		} else {
			SetGoals(current_goals_count - 1);
		}
	}

	const addGoals = () => {
		updateGoals("+");
	}

	const reduceGoals = () => {
		if (enforceType(Item.goals, 'number') === 0) {
			// do nothing
		} else {
			updateGoals("-");
		}
	}

	const modifyPlayerAssists = (val, operation) => {
		console.log(val, operation);
		let _assists = enforceType(playerAssists, 'number');

		if (_assists === 0 && operation === '-') {
			SetPlayerAssists(0);
		} else {
			if (operation === '+') {
				SetPlayerAssists(_assists + 1);
			} else {
				SetPlayerAssists(_assists - 1);
			}
		}
		postData();
	}

	const modifyPlayerPlusMinus = (val, operation) => {
		console.log(val, operation);
		let _plusMinus = enforceType(playerPlusMinus, 'number');

		if (_plusMinus === 0 && operation === '-') {
			SetPlayerPlusMinus(0);
		} else {
			if (operation === '+') {
				SetPlayerPlusMinus(_plusMinus + 1);
			} else {
				SetPlayerPlusMinus(_plusMinus - 1);
			}
		}
		postData();
	}

	const modifyPlayerShots = (val, operation) => {
		console.log(val, operation);
		let _playerShots = enforceType(playerShots, 'number');

		if (_playerShots === 0 && operation === '-') {
			SetPlayerShots(0);
		} else {
			if (operation === '+') {
				SetPlayerShots(_playerShots + 1);
			} else {
				SetPlayerShots(_playerShots - 1);
			}
		}
		postData();
	}

	const modifyGameScore = (val, operation, team) => {
		console.log(val, operation, team);

		if (team === "home") {
			let _homeTeamScore = enforceType(homeTeamScore, 'number');

			if (_homeTeamScore === 0 && operation === '-') {
				SetHomeTeamScore(0);
			} else {
				if (operation === '+') {
					SetHomeTeamScore(_homeTeamScore + 1);
				} else {
					SetHomeTeamScore(_homeTeamScore - 1);
				}
			}

			postData();

		} else if (team === "away") {
			let _awayTeamScore = enforceType(awayTeamScore, 'number');

			if (_awayTeamScore === 0 && operation === '-') {
				SetAwayTeamScore(0);
			} else {
				if (operation === '+') {
					SetAwayTeamScore(_awayTeamScore + 1);
				} else {
					SetAwayTeamScore(_awayTeamScore - 1);
				}
			}

			// Away team goals have changed which impacts goaltender statistics
			modifyGoalieStatz(operation, "shotsAgainst_and_goalsAgainst");
		}
	}

	const modifySaveStatz = (shotsAgainst_count, goalsAgainst_count) => {
		// saves
		let saves_made = shotsAgainst_count - goalsAgainst_count;
		SetSaves(saves_made);

		// save percentage
		SetSavePercentage(saves_made / shotsAgainst_count);
	}

	const modifyGoalieStatz = (operation, key) => {
		console.log(operation, key);
		let _shotsAgainst  = enforceType(shotsAgainst, 'number');
		let _awayTeamScore = enforceType(awayTeamScore, 'number');

		if (operation === '-' && key === 'shotsAgainst' && ((_shotsAgainst - 1 < _awayTeamScore))) {
			// do nothing -- shots against cannot be less than away team score
			console.log('do nothing');
		} else if (_awayTeamScore === 0 && operation === '-' && key === 'shotsAgainst' && _shotsAgainst > 0) {
			// allow use to update only the shots against and still get save statz updated
			SetShotsAgainst(_shotsAgainst - 1);
			modifySaveStatz(_shotsAgainst - 1, _awayTeamScore);
		} else if (_awayTeamScore === 0 && operation === '-') {
			// do nothing -- away team score cannot be a negative value
		} else if (_awayTeamScore === 0 && _shotsAgainst === 0 && operation === '-') {
			// do nothing -- neither the away team score nor the shots against values can be negative
		} else if (_shotsAgainst === 0 && operation === '-') {
			// do nothing -- the shots against value cannot be negative
			modifySaveStatz(0, _awayTeamScore);
		} else {

			if (key === 'shotsAgainst_and_goalsAgainst') {
				// away team score has changed, which changes the goalie's shots against and
				// goals against but does not change their saves since the shot was a goal
				if (operation === '+') {
					SetShotsAgainst(_shotsAgainst + 1);
					SetGoalsAgainst(_awayTeamScore + 1);
					modifySaveStatz(_shotsAgainst + 1, _awayTeamScore + 1);
				} else {
					// SetShotsAgainst(_shotsAgainst - 1);
					SetGoalsAgainst(_awayTeamScore - 1);
					modifySaveStatz(_shotsAgainst, _awayTeamScore - 1);
				}

				// since the score has changed we should check to see if perhaps
				// the away team's goal was reversed by officials reviewing the play
				// and if the reversal results in the away team's score equal to zero
				// then we should zero out the goals against, set saves equal to shots
				// against, and set save percentage to 100%
				if (operation === '-' && _awayTeamScore === 0) {
					// handle negative number attempts
					SetGoalsAgainst(0);
					// since there are no goals against, set save percentage to 100%
					SetSavePercentage(1);
				}

			} else if (key === 'shotsAgainst') {
				// Nice Save! The score remains the same so do not change it.
				// The goalie made a save, so credit their shotsAgainst and Saves
				if (operation === '+') {
					SetShotsAgainst(_shotsAgainst + 1);
					modifySaveStatz(_shotsAgainst + 1, _awayTeamScore);
				} else {
					// shots have gone down...this usually happens
					// when the user accidentally adds too many shots
					SetShotsAgainst(_shotsAgainst - 1);
					modifySaveStatz(_shotsAgainst - 1, _awayTeamScore);

					if ((_shotsAgainst - 1) === 0) {
						// if there are no shots against, then there are no goals against
						modifySaveStatz(0, 0);
					}
				}

			} else if (key === 'shotsFor') {
				// score remains the same, but home team has make new shots on net
				if (operation === '+') {

				} else {

				}

			}
		}
	}

	const renderPositionEditControls = () => {
		console.log('renderPositionEditControls ', playerPosition);
		if (playerPosition === "SKATER") {
			return (
				<div className={"metrics_in_editor"}>
					<div className="">
						<h3 className="pb-0">Player Goals:</h3>
						<TextField label={goals} disabled={true}/>
						<span onClick={reduceGoals} color="inherit" className={'m20'}>
							<RemoveCircleRounded className="PositionEditControls incrementer"/>
						</span>
						<span onClick={addGoals} color="inherit" className={'m20'}>
							<AddCircle className="PositionEditControls incrementer green"/>
						</span>
					</div>
					<div className="">
						<h3 className="pb-0">Player Assists:</h3>
						<TextField label={playerAssists} disabled={true}/>
						<span className={'m20'} color="inherit"
									onClick={(e) => modifyPlayerAssists(e.target.value, "-")}>
							<RemoveCircleRounded className="PositionEditControls incrementer"/>
						</span>
						<span className={'m20'} color="inherit"
									onClick={(e) => modifyPlayerAssists(e.target.value, "+")}>
							<AddCircle className="PositionEditControls incrementer green"/>
						</span>
						<br/>
					</div>
					<div className="">
						<h3 className="pb-0">Player Shots:</h3>
						<TextField label={playerShots} disabled={true}/>
						<span className={'m20'} color="inherit"
									onClick={(e) => modifyPlayerShots(e.target.value, "-")}>
							<RemoveCircleRounded className="PositionEditControls incrementer"/>
						</span>
						<span className={'m20'} color="inherit"
									onClick={(e) => modifyPlayerShots(e.target.value, "+")}>
							<AddCircle className="PositionEditControls incrementer green"/>
						</span>
						<br/>
					</div>
					<div className="">
						<h3 className="pb-0">Player +/-:</h3>
						<TextField label={playerPlusMinus} disabled={true}/>
						<span className={'m20'} color="inherit"
									onClick={(e) => modifyPlayerPlusMinus(e.target.value, "-")}>
							<RemoveCircleRounded className="PositionEditControls incrementer"/>
						</span>
						<span className={'m20'} color="inherit"
									onClick={(e) => modifyPlayerPlusMinus(e.target.value, "+")}>
							<AddCircle className="PositionEditControls incrementer green"/>
						</span>
						<br/>
					</div>
				</div>
			)
		} else {
			return (
				<div className={"metrics_in_editor"}>
					<div className="">
						<h2 className="m10">Saves:<br/><span className={'blue'}>{saves}</span></h2>
					</div>
					<div className="">
						<h2 className="m10">Save %:<br/><span className={'blue'}>{savePercentage + "%"}</span></h2>
					</div>
				</div>
			)
		}
	}

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className="update_record_model"
			>
				<Box sx={style} className={"data_card"}>
					<h2 className={"center_text"}>Edit Game</h2>
					<div className={'scoreboard_editor_out'}>
						<div className={'scoreboard_editor_in'}>
							<div className={'scoreboard_top_row'}>
								<div className={'scoreboard_controls mr-15'}>
									<span className={'control_buttons'} color="inherit"
												onClick={(e) => modifyGameScore(e.target.value, "-", "home")}>
										<RemoveCircleRounded className="score_minus_first incrementer"/>
									</span>
									<span>{Item.team}</span>
									<div className={'scoreboard_goals'}>
										{homeTeamScore}
									</div>
									<span className={'control_buttons'}
												onClick={(e) => modifyGameScore(e.target.value, "+", "home")}>
										<AddCircle className="score_add incrementer green"/>
									</span>
								</div>
								<div className={'center_text'}>
									<small className={'green'}>HockeyStatZ.com</small>
									<div className={'scoreboard_time_date red'}>
										{Item.date.split(', ')[1].split(new RegExp(':[0-9][0-9] '))[0]}
									</div>
									<small className={'red'}>{Item.date.split(',')[0]}</small>
								</div>
								<div className={'scoreboard_controls ml-15'}>
									<span className={'control_buttons'} color="inherit"
												onClick={(e) => modifyGameScore(e.target.value, "-", "away")}>
										<RemoveCircleRounded className="score_minus incrementer"/>
									</span>
									<span>OPPONENT</span>
									<div className={'scoreboard_goals'}>
										{awayTeamScore}
									</div>
									<span className={'control_buttons'}
												onClick={(e) => modifyGameScore(e.target.value, "+", "away")}>
										<AddCircle className="score_add incrementer green"/>
									</span>
								</div>
							</div>
							<div className={'scoreboard_bottom_row'}>
								<div className={'scoreboard_controls mr-15'}>
									<span className={'control_buttons'} color="inherit"
												onClick={(e) => modifyGoalieStatz("-", "shotsFor")}>
										<RemoveCircleRounded className="shots_minus_first incrementer"/>
									</span>
									<div className={'scoreboard_goals'}>
										...
									</div>
									<span className={'control_buttons'}
												onClick={(e) => modifyGoalieStatz("+", "shotsFor")}>
										<AddCircle className="shots_add incrementer green"/>
									</span>
								</div>
								<div className={'center_text'}>
									<p className={'m10'}>Shots On<br/>Goal</p>
								</div>
								<div className={'scoreboard_controls ml-15'}>
									<span className={'control_buttons'} color="inherit"
												onClick={(e) => modifyGoalieStatz("-", "shotsAgainst")}>
										<RemoveCircleRounded className="shots_minus incrementer"/>
									</span>
									<div className={'scoreboard_goals'}>
										{shotsAgainst}
									</div>
									<span className={'control_buttons'}
												onClick={(e) => modifyGoalieStatz("+", "shotsAgainst")}>
										<AddCircle className="shots_add incrementer green"/>
									</span>
								</div>
							</div>
						</div>
					</div>

					{renderPositionEditControls()}

					<Button className="save_and_close" variant="contained" onClick={handleClose}>
						Close
					</Button>
					<small className={"pre_save_msg"}>Data is saved automatically.</small>
				</Box>
			</Modal>
			<div className={"edit_button_container"} container spacing={2}>
					<span className="edit_button" variant="contained" onClick={handleOpen}>
						Edit
					</span>
			</div>
		</div>
	);
}
