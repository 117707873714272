import React         from 'react';

import '../global.css';
import email_example from '../images/email_example.jpg';
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@material-ui/core";

export default () => (
	<div>
		<div className="dark_mini"></div>
		<div className={"p-15"}>
			<h1 className={" mt-50"}>
				Thank You & Welcome to the team!
			</h1>
			<p>Your account will become active within 24 hours of receiving player registration.</p>

			<Accordion>
				<AccordionSummary
					expandIcon={'+'}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography>Look for an email from "noreply@test-account.dev"</Typography>
				</AccordionSummary>
				<AccordionDetails className={"contact_form"}>
					<p>Please monitor for an email from "noreply@test-account.dev". It will look similar to this:</p>
					<img src={email_example} width="250px" alt={""}/>
				</AccordionDetails>
			</Accordion>

		</div>

		<div id="zf_div_RBdy1DgUn_I-Xy_I_8cs_WHUnfm8kOVmTu49GTjWD7g"></div>
	</div>
);