import React, {useState, useEffect} from 'react';
import axios from 'axios';
import UpdateRecordModal from "../components/UpdateRecordModal";
import {
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  Typography,
  Box,
  Tabs,
  Tab,
  Grid, Container
} from '@material-ui/core';
import DeleteRecordModal from "../components/DeleteRecordModal";
import '../global.css';
import {Timeline as TimelineIcon} from '@material-ui/icons';
import AddGameModal from "../components/AddGameModal";
import SkaterMetrics from "../components/SkaterMetrics";
import GoalieMetrics from "../components/GoalieMetrics";
import {withStyles, makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 14
  }
}))(TableCell);
const StyledTableRow  = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

function RenderResult(props) {
  console.log('RenderResult() called ... ', props);

  // const [name, setName]				= useState(props.params[0].tables[0]);
  const [value, setValue]                   = useState(0);
  const [isLoading, setLoading]             = useState(true);
  const [apiResponse, setApiResponse]       = useState(props.params[0].apiResponse);
  const [playerTable, setPlayerTable]       = useState(props.params[0].tables[0].name);
  const [playerPosition, setplayerPosition] = useState(props.params[0].tables[0].position);
  const url                                 = process.env.REACT_APP_ANYTHING_API;
  const KEY                                 = process.env.REACT_APP_ANYTHING_KEY;
  const headers                             = {
    'content-type': 'application/json',
    accept        : 'application/json',
    'x-api-key'   : `${KEY}`
  };

  useEffect(() => {
    fetchPlayerTable(playerTable);
  }, [url]);

  const sortByDate = function (arr) {
    function sorter(a, b) {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    }

    return arr.sort(sorter);
  };

  const fetchPlayerTable = (player) => {
    axios.get(url + `?TableName=${player}`, {headers})
         .then(response => {
           console.log('response from table ', response);
           let SORTED_OBJ = sortByDate(response.data.Items);
           console.log("SORTED OBJ ", SORTED_OBJ);
           setApiResponse(SORTED_OBJ.reverse());
           setLoading(false);
           console.log("player = ", playerTable);
         })
         .catch(error => {
           console.warn("error::: ", error);
           setApiResponse("There was an Error retrieving data.");
           setLoading(false);
         });
  };

  const handleChange = (event, newValue) => {
    setApiResponse({});
    let player   = props.params[0].tables[newValue].name;
    let position = props.params[0].tables[newValue].position;
    console.log('handle Player Change = ', props.params[0].tables, event, newValue);
    setLoading(true);
    setPlayerTable(player);
    setplayerPosition(position);
    setValue(newValue);
    fetchPlayerTable(player);
  };

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  const renderPositionCharts = () => {
    if (playerPosition === 'SKATER') {
      return <SkaterMetrics {...[apiResponse]}/>;
    } else {
      return <GoalieMetrics {...[apiResponse]}/>;
    }
  };

  const renderAdvertisement = index => {
    console.log('advertisement index if odd, then display: ', index);
    const isOdd = index % 2;
    if (isOdd === 1) {
      return (
        <div>
          {/*<div className={"advertise"}>*/}
          {/*	<h3><a href={"/advertise"}>ADVERTISE HERE</a></h3>*/}
          {/*</div>*/}
        </div>
      );
    }
  };

  return (
    <div>
      <div className="dark_mini">
        <div>
          <Typography variant="h4">Games</Typography>
          {/*<small>Email: {props.params[0].email}</small><br/>*/}
          {/*<small>ID: {props.params[0].sub}</small><br/>*/}
          <AddGameModal params={[props.params[0].sub, playerTable]}/>
        </div>

        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <small className="green">Position: </small>{playerPosition}
          <Tabs value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="secondary">
            {props.params[0].tables.map((table, i) => (
              <Tab key={i} label={table.name.split('_')[0] + " " + table.name.split('_')[1]}/>
            ))}
          </Tabs>
        </Box>
      </div>

      <Accordion className="accord_pop">
        <AccordionSummary
          expandIcon="⥥"
          aria-controls="panel1a-content"
          id="panel1a-header">
          <TimelineIcon/><Typography variant="body1">&nbsp;Tap To Reveal Data Trends</Typography>
        </AccordionSummary>
        {/*<AccordionDetails>*/}
        {renderPositionCharts()}
        {/*</AccordionDetails>*/}
      </Accordion>

      {
        apiResponse.map((item, i) => (
          <div style={{padding: "30px 10px 10px 10px"}} className={"ledger_block"}>
            <p>{item.league === "SDP" ? "AAA" : item.league} {item.level} on <i>{item.date.split(',')[0]}</i><UpdateRecordModal params={[item, playerTable, playerPosition]}/></p>
            <DeleteRecordModal params={[item, playerTable]}/>
            <Grid container style={{margin: "20px 5px"}}>
              <Grid xs={5} className={"pt-5 center_text"}>
                <div className={"team_score_titles"}>
                  <h3>
                    <span className={`${(item.homeTeamScore > item.awayTeamScore) ? 'green_highlight' : 'red_highlight'}`}>{item.team}: <b>{item.homeTeamScore}</b></span>
                    <br/>
                    <i>vs.</i>
                    <br/>
                    <span>{item.opponent}: <b>{item.awayTeamScore}</b></span>
                  </h3>
                </div>
              </Grid>
              <Grid xs={3}>
                <Grid xs={12} className={"border-right border-bottom pt-5 pb-10"}>
                  <Grid container>
                    <Grid xs={12} className={"center_text"}>
                      {(playerPosition === "SKATER") ? (<div>Goals</div>) : (<div>GA</div>)}
                    </Grid>
                    <Grid xs={12} className={"center_text"}>
                      {(playerPosition === "SKATER") ? (<div><b>{item.goals}</b></div>) : (<div><b>{item.goalsAgainst}</b></div>)}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12} className={"border-right pt-5 pb-5"}>
                  <Grid container>
                    <Grid xs={12} className={"center_text"}>
                      {(playerPosition === "SKATER") ? (<div>Shots</div>) : (<div>SA</div>)}
                    </Grid>
                    <Grid xs={12} className={"center_text"}>
                      {(playerPosition === "SKATER") ? (<div><b>{item.playerShots}</b></div>) : (<div><b>{item.shotsAgainst}</b></div>)}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={3}>
                <Grid xs={12} className={"border-bottom pt-5 pb-10"}>
                  <Grid container>
                    <Grid xs={12} className={"center_text"}>
                      {(playerPosition === "SKATER") ? (<div>Assists</div>) : (<div>S%</div>)}
                    </Grid>
                    <Grid xs={12} className={"center_text"}>
                      {(playerPosition === "SKATER") ? (<div><b>{item.playerAssists}</b></div>) : (<div><b>{item.savePercentage}</b></div>)}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12} className={"pt-5 pb-5"}>
                  <Grid container>
                    <Grid xs={12} className={"center_text"}>
                      {(playerPosition === "SKATER") ? (<div>+/-</div>) : (<div>GAA</div>)}
                    </Grid>
                    <Grid xs={12} className={"center_text"}>
                      {(playerPosition === "SKATER") ? (<div><b>{item.playerPlusMinus}</b></div>) : (<div><b>{item.gaa}</b></div>)}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={1}></Grid>
            </Grid>
          </div>
        ))
      }

    </div>
  );
}

export default RenderResult;
