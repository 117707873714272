import React, { Component } from 'react';
import {
	Button, Typography
}                           from '@material-ui/core';
import { withOktaAuth }     from '@okta/okta-react';

class AuthText extends Component {
	state = {
		authenticated: null,
		user: null,
		menuAnchorEl: null,
	};

	componentDidUpdate() {
		this.checkAuthentication();
	}

	componentDidMount() {
		this.checkAuthentication();
	}

	async checkAuthentication() {
		const authenticated = this.props.authState.isAuthenticated;
		if (authenticated !== this.state.authenticated) {
			const user = await this.props.authService.getUser();
			console.log('user ', user)
			this.setState({ authenticated, user });
		}
	}

	render() {

		if (this.state.authenticated === null) return null;
		if (!this.state.authenticated) return (
			<div>
				<div className={"homepage_intro"}>
					<Typography variant="h4">
						TRACK YOUR PLAYERS
					</Typography>
					<br/>
					<span className={"pill"}>Goals</span>
					<span className={"pill"}>Assists</span>
					<span className={"pill"}>+/-</span>
					<span className={"pill"}>Save %</span>
					<span className={"pill"}>Shots</span>
					<span className={"pill"}>GAA</span>
					<span className={"pill"}>& More</span>
					<br/>
					<br/>
					<Button href={"/demo"} variant="contained" className={"homepage_button mt-15"}>
						Explore The Demo <b>&nbsp;›</b>
					</Button>
					<br/>
				</div>
				<div className={"down_arrow_container"}>
					<div className={'down_arrow'}></div>
				</div>
			</div>
		);

		return (
			<div className={"homepage_intro"}>
				<p>
					Thank you for your business!
				</p>
				<Typography variant="subtitle1">
					<br/>
					<a href="/statz" className="white">Go To Stats Page <b>›</b></a>
					<br/>
					<br/>
					<br/>
				</Typography>
			</div>
		);
	}
}

export default withOktaAuth(AuthText);
