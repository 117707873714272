import * as React from 'react';

function PricingPage() {
    // Paste the stripe-pricing-table snippet in your React component
    return (
        <stripe-pricing-table
            pricing-table-id="prctbl_1NRhG2BvkqU0PIXpDb4U8o4w"
            publishable-key="pk_live_51KziA3BvkqU0PIXpoqXqHCd1HjTNH0KOQVLpbDBvWnG9ZJbTCWx4pFLiUNcTYsHXzlztnXiJfjgXCXIGVHY4Mq1D00aYuvM7MT"
        >
        </stripe-pricing-table>
    );
}

export default PricingPage;