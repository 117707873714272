import * as React         from 'react';
import LineChart          from "./LineChart";


export default function SkaterMetrics(apiResponse) {
	return (
		<div className={"metrics_accordion"}>
			<h2>Goal Trends:</h2>
			<LineChart params={['Goals', apiResponse]}/>

			<h2>Assist Trends:</h2>
			<LineChart params={['Assists', apiResponse]}/>
		</div>
	);
}
