import React from 'react';
import {
  Typography
} from '@material-ui/core';
import {
  Button
} from '@material-ui/core';
import '../global.css';
// import DemoModal      from "../components/DemoModal";
import assist from "../images/savepercentagev2.png";
import goals from "../images/goaltrendsv2.png";
import insta from "../images/insta.png";
import groupme from "../images/groupme.png";
import more from "../images/more.png";
import add from "../images/add.png";
// import quote from "../images/quote.png";
import {AddToHomeScreen, SportsHockey} from "@material-ui/icons";
import {Rating} from "@material-ui/lab";
import AuthText from "../components/AuthText";
import AddToHomeScreenTip from '../components/AddToHomeScreenTip';
import PricingPage from "../components/PricingPage";

let content_variations = {
  num_1: {
    title: "Move Your Feet!",
    subtitle: "Quit standing on the ice like it's a family picnic, and let's see some turbocharged footwork out there! Go, go, go!"
  },
  num_2: {
    title: "Take The Body!",
    subtitle: "You're like a force of nature out there, a hockey tornado wreaking havoc on the opponents' plans."
  },
  num_3: {
    title: "Hi, Mom!",
    subtitle: "Every moment matters - like giving Mom a shout out at State Tourney. HockeyStatz was built to by hockey players for hockey players & parents."
  },
  num_4: {
    title: "Pass The Puck!",
    subtitle: "Pass that puck like you're delivering the game-winning pizza to the net!"
  }
}

export default function HomePage() {

  const num = {};

  const content = (res) => {
    return (
      <div>
        <h2 className={"center_text pt-20 quote_quote"}>{res.title}</h2>
        <Typography className={"center_text"} variant="body1">
          {res.subtitle}
        </Typography>
      </div>
    )
  }

  const grab_content_variation = () => {
    let _num = parseInt((Math.random()*100).toFixed(0));
    if (_num <= 25) {
      let res = content_variations.num_1;
      console.log(res);
      return content(res);
    } else if (_num > 26 && _num <= 50) {
      let res = content_variations.num_2;
      console.log(res);
      return content(res);
    } else if (_num > 50 && _num <= 75) {
      let res = content_variations.num_3;
      console.log(res);
      return content(res);
    } else {
      let res = content_variations.num_4;
      console.log(res);
      return content(res);
    }
  }

  return (
    <div className={"mb-50"}>
      <div className="dark_jumbo">
        <AuthText/>
        <br/>
        <br/>

      </div>
      <br/>
      <div className={"p-15"}>
        <h2 className={"center_text"}>Sign Up Today</h2>
        <PricingPage/>
        <br/>
        <p className={"center_text m20 mb-50"}>
          After payment, you will be directed to a webpage allowing you to configure your skaters
          and goalies. Active subscriptions are easily managed in the Profile page.
        </p>
      </div>
      <div className={"home_container mb-50"}>

        {/*{grab_content_variation()}*/}
        {/*<div className="image_viewer">*/}
        {/*  <img className="img_l" alt="" src={assist}/>*/}
        {/*  <img className="img_r" alt="" src={goals}/>*/}
        {/*</div>*/}
        {/*<div className="body_jumbo pt-20">*/}
        {/*  <a href={"https://web.groupme.com/join_group/94740093/fxUMqQmp"} target={"_blank"}>*/}
        {/*    <img className={"social_icons"} src={groupme} alt={"group me logo"}/>*/}
        {/*  </a>*/}
        {/*  <a href={"https://www.instagram.com/hockeystatz/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"} target={"_blank"}>*/}
        {/*    <img className={"social_icons"} src={insta} alt={"insta logo"}/>*/}
        {/*  </a>*/}
        {/*  <br/>*/}

          <p>
            Take full control of your data, right from your phone. No more notebooks, score sheets or
            seasonal data lost to time and deletion. Track yourself or your favorite athletes —
            isolate improvement opportunities or simply record for the fun of it. HockeyStatz gets you closer
            to the game than ever before.
          </p>


          {/*<br/>*/}
          {/*<div className="price">*/}
          {/*	<Typography variant="body1" className="white">*/}
          {/*		MONTHLY*/}
          {/*	</Typography>*/}
          {/*	<h1 className={"dark_gray"}>*/}
          {/*		<sup>$</sup>*/}
          {/*		4<sup><b>.99</b></sup>*/}
          {/*	</h1>*/}
          {/*	<Button variant="contained" href="/#request_access">*/}
          {/*		Sign Up*/}
          {/*	</Button>*/}
          {/*	<br/>*/}
          {/*	<br/>*/}
          {/*	<div className="price_content">*/}
          {/*		<b>Core Features:</b>*/}
          {/*		<br/>*/}
          {/*		<SportsHockey/>You enter your players data.<SportsHockey/>*/}
          {/*		<br/>*/}
          {/*		<SportsHockey/>Track your athletes statistics.<SportsHockey/>*/}
          {/*		<br/>*/}
          {/*		<SportsHockey/>See player comparisons.<SportsHockey/>*/}
          {/*	</div>*/}
          {/*	<hr/>*/}
          {/*	<sup><i className="center_text">We add more features each month!</i></sup>*/}
          {/*	<Rating name="read-only" value={4} readOnly/>*/}
          {/*	<br/>*/}
          {/*	<Typography variant="subtitle1">*/}
          {/*		<i>*/}
          {/*			"I love being able to monitor my sons progress over the years."*/}
          {/*		</i>*/}
          {/*	</Typography>*/}
          {/*	<br/>*/}
          {/*	/!*<div id="zf_div_66m2zlPJ7xmDk7dN5j2zOiR9GkIV7jIqKFLo8XbJLNs"></div>*!/*/}
          {/*</div>*/}
          {/*<Typography variant="body2" className="left_align pt-5">*/}
          {/*	Here is the list of data you can collect and view trends for, included below:*/}
          {/*	<br/>*/}
          {/*	<b>Skaters:</b> Goals, Assists, Game Results*/}
          {/*	<br/>*/}
          {/*	<b>Goalies:</b> Shots Against, Goals Against Average, Save Percentage, Game Results*/}
          {/*</Typography>*/}
          <br/>
          {/*<iframe title="Request Access"*/}
          {/*		id="request_access"*/}
          {/*		src="https://forms.zohopublic.com/hockeystatz/form/Test/formperma/RBdy1DgUn_I-Xy_I_8cs_WHUnfm8kOVmTu49GTjWD7g"*/}
          {/*		frameBorder="0" marginWidth="0" marginHeight="0"*/}
          {/*></iframe>*/}
        </div>
        <span id={"request_access"}></span>
        {/*<div id="zf_div_RBdy1DgUn_I-Xy_I_8cs_WHUnfm8kOVmTu49GTjWD7g"></div>*/}



        <br/>

        {/*<AddToHomeScreenTip/>*/}

    </div>
  );
}