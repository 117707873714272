import React, {Component, Fragment} from 'react';
import {withOktaAuth} from '@okta/okta-react';
import {withRouter} from 'react-router-dom';
import {
  withStyles
} from '@material-ui/core';
import {compose} from 'recompose';
import ErrorSnackbar from '../components/ErrorSnackbar';
import RenderResult from "../functions/RenderResult";
import axios from "axios";
import * as url from 'url';

const uri     = process.env.REACT_APP_ANYTHING_API;
const KEY     = process.env.REACT_APP_ANYTHING_KEY;
const headers = {
	'content-type': 'application/json',
	accept        : 'application/json',
	'x-api-key'   : `${KEY}`
};

const styles = theme => ({
  posts: {
    marginTop: theme.spacing(2)
  },
  fab  : {
    position                      : 'absolute',
    bottom                        : theme.spacing(3),
    right                         : theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      bottom: theme.spacing(2),
      right : theme.spacing(2)
    }
  }
});

class PostsManager extends Component {
  state = {
    apiResponse: null,
	  loading: true,
    posts  : {},
    error  : null,
    name   : "",
    user   : null,
    email  : null,
    sub    : null,
    tables : []
  };

  componentDidMount() {
    this.checkAuthentication();
  }

  async checkAuthentication() {
    const authenticated = this.props.authState.isAuthenticated;
    if (authenticated !== this.state.authenticated) {
      const user = await this.props.authService.getUser();
      console.log('>>>>>>user ', user)
      this.setState({email: user.email});
      this.setState({sub: user.sub});
      this.getPosts();
    }
  }

  // async fetch(method, endpoint, body) {
  // 	try {
  // 		const response = await fetch(`${API}${endpoint}`, {
  // 			method,
  // 			body   : body && JSON.stringify(body),
  // 			headers: {
  // 				'content-type': 'application/json',
  // 				accept        : 'application/json',
  // 				'x-api-key'   : `${KEY}`,
  // 				authorization : `Bearer ${await this.props.authService.getAccessToken()}`,
  // 			},
  // 		});
  // 		console.log('fetch response = ', response.json());
  // 		return await response.json();
  // 	}
  // 	catch (error) {
  // 		console.error(error);
  //
  // 		this.setState({error});
  // 	}
  // }

	sortByDate(arr) {
		function sorter(a, b) {
			return new Date(a.date).getTime() - new Date(b.date).getTime();
		}

		return arr.sort(sorter).reverse();
	};

	async fetchPlayerTable(player) {
		console.log('hi');
		axios.get(uri + `?TableName=${player}`, {headers})
		     .then(response => {
			     console.log('response from table ', response);
			     let SORTED_OBJ = this.sortByDate(response.data.Items);
			     console.log("SORTED OBJ ", SORTED_OBJ);
			     this.setState({apiResponse: SORTED_OBJ});
           this.setState({loading: false});
			     console.log("is loading? ", this.state.loading);
		     })
		     .catch(error => {
			     console.warn("error::: ", error);
           this.setState({loading: false});
			     // setApiResponse("There was an Error retrieving data.");
			     // setLoading(false);
		     });
	}

  async getPosts() {

    // this.setState({loading: false, posts: (await this.fetch('get', '?TableName=accounts')) || []});
    // // this.state.apiResponse = RenderResult();

    // useEffect(() => {
    axios.get(uri + `?TableName=${this.state.sub}`, {headers})
         .then(response => {
           console.log('getPosts response from table ', response);

           this.setState({tables: response.data.Items[0].tables});

           console.log('state = ', this.state);
           console.log('tabless = ', this.state.tables);

           let player = response.data.Items[0].tables[0];
           // let name_cap = player_name.split('_')[0].charAt(0).toUpperCase() + player_name.slice(1);
           // let formatted_player_name     = name_cap.split('_')[0]
           console.log('player name ', player.name);
	         this.fetchPlayerTable(player.name)
           // this.setState({name});
         })
         .catch(error => {
           console.warn("getPosts error::: ", error);
           this.setState({loading: false});
         });
    // }, [uri]);
  }

  // savePost = async (post) => {
  // 	if (post.id) {
  // 		await this.fetch('put', `/posts/${post.id}`, post);
  // 	} else {
  // 		await this.fetch('post', '/posts', post);
  // 	}
  //
  // 	this.props.history.goBack();
  // 	await this.getPosts();
  // }

  // async deletePost(post) {
  // 	if (window.confirm(`Are you sure you want to delete "${post.title}"`)) {
  // 		await this.fetch('delete', `/posts/${post.id}`);
  // 		await this.getPosts();
  // 	}
  // }

  // renderPostEditor = ({match: {params: {id}}}) => {
  // 	if (this.state.loading) return null;
  // 	const post = find(this.state.posts, {id: Number(id)});
  //
  // 	if (!post && id !== 'new') return <Redirect to="/posts"/>;
  //
  // 	return <PostEditor post={post} onSave={this.savePost}/>;
  // };

	render() {
		// const {classes} = this.props;
		if (this.state.loading) {
      return <div className="loading center_text mt-15"><h2>Changing Lines...</h2>{this.state.loading}</div>
		} else {
			return (
				<Fragment>
					<RenderResult params={[this.state]}/>
					{this.state.error && (
						<ErrorSnackbar
							onClose={() => this.setState({error: null})}
							message={this.state.error.message}
						/>
					)}
				</Fragment>
			);
		}
	}
}

export default compose(
  withOktaAuth,
  withRouter,
  withStyles(styles)
)(PostsManager);
