import React                      from 'react';
import {
	AppBar,
	Toolbar,
	Typography,
	withStyles,
} from '@material-ui/core';
import logo                       from '../images/logo-2023.png';
import LoginButton                from './LoginButton';
import '../global.css';

const styles = {
	flex: {
		flex: 1,
	},
};

const AppHeader = ({classes}) => (
	<AppBar position="static" className="header">
		<Toolbar>
			<Typography variant="h6" color="inherit">
				<a href="/"><img src={logo} width="150px" alt="logo"/></a>
			</Typography>
			<div className={classes.flex}/>
			<LoginButton/>
		</Toolbar>
	</AppBar>
);

export default withStyles(styles)(AppHeader);