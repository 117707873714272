import React, {useState, useEffect} from 'react';
import DemoUpdateRecordModal        from "../components/DemoUpdateRecordModal";
import {
	List,
	ListItem,
	ListItemText,
	Accordion,
	AccordionSummary,
	Typography,
	Box,
	Tabs,
	Tab, Grid
}                                   from '@material-ui/core';
import DemoDeleteRecordModal        from "../components/DemoDeleteRecordModal";
import '../global.css';
import {Timeline as TimelineIcon}   from '@material-ui/icons';
import DemoAddGameModal             from "../components/DemoAddGameModal";
import SkaterMetrics                from "../components/SkaterMetrics";
import GoalieMetrics                from "../components/GoalieMetrics";
import UpdateRecordModal from '../components/UpdateRecordModal';
import DeleteRecordModal from '../components/DeleteRecordModal';
import AddToHomeScreenTip from '../components/AddToHomeScreenTip';

function RenderResult(props) {
	console.log('DemoRenderResult() called ... ', props);
	const demo_alex   = {
		"Items": [
			{
				"saves": 9,
				"goalsAgainst": 1,
				"savePercentage": 92.35,
				"goals": 0,
				"name": "Alex_Dahl",
				"opponent": "LIONS",
				"date": "4/17/2023, 10:51:40 AM",
				"shotsAgainst": 10,
				"level": "MITE 2",
				"homeTeamScore": 8,
				"playerAssists": 0,
				"account": "00u4jfl64iBro689g5d7",
				"team": "BUCKEYES",
				"awayTeamScore": 0,
				"league": "OMGHA"
			},
			{
				"saves": 7,
				"goalsAgainst": 2,
				"savePercentage": 89.42,
				"goals": 1,
				"name": "Alex_Dahl",
				"opponent": "SHARKS",
				"date": "4/18/2023, 11:25:10 AM",
				"shotsAgainst": 9,
				"level": "MITE 2",
				"homeTeamScore": 4,
				"playerAssists": 1,
				"account": "00u4jfl64iBro689g5d7",
				"team": "BUCKEYES",
				"awayTeamScore": 2,
				"league": "OMGHA"
			},
			{
				"saves": 8,
				"goalsAgainst": 0,
				"savePercentage": 91.75,
				"goals": 0,
				"name": "Alex_Dahl",
				"opponent": "TIGERS",
				"date": "4/19/2023, 09:12:55 AM",
				"shotsAgainst": 8,
				"level": "MITE 2",
				"homeTeamScore": 6,
				"playerAssists": 2,
				"account": "00u4jfl64iBro689g5d7",
				"team": "BUCKEYES",
				"awayTeamScore": 0,
				"league": "OMGHA"
			},
			{
				"saves": 6,
				"goalsAgainst": 3,
				"savePercentage": 88.67,
				"goals": 1,
				"name": "Alex_Dahl",
				"opponent": "WOLVES",
				"date": "4/20/2023, 08:05:20 AM",
				"shotsAgainst": 9,
				"level": "MITE 2",
				"homeTeamScore": 2,
				"playerAssists": 0,
				"account": "00u4jfl64iBro689g5d7",
				"team": "BUCKEYES",
				"awayTeamScore": 3,
				"league": "OMGHA"
			},
			{
				"saves": 7,
				"goalsAgainst": 1,
				"savePercentage": 93.21,
				"goals": 0,
				"name": "Alex_Dahl",
				"opponent": "BEARS",
				"date": "4/21/2023, 07:00:05 AM",
				"shotsAgainst": 8,
				"level": "MITE 2",
				"homeTeamScore": 5,
				"playerAssists": 1,
				"account": "00u4jfl64iBro689g5d7",
				"team": "BUCKEYES",
				"awayTeamScore": 1,
				"league": "OMGHA"
			},
			{
				"saves": 5,
				"goalsAgainst": 0,
				"savePercentage": 92.10,
				"goals": 0,
				"name": "Alex_Dahl",
				"opponent": "LIONS",
				"date": "4/22/2023, 08:34:15 AM",
				"shotsAgainst": 5,
				"level": "MITE 2",
				"homeTeamScore": 4,
				"playerAssists": 0,
				"account": "00u4jfl64iBro689g5d7",
				"team": "BUCKEYES",
				"awayTeamScore": 0,
				"league": "OMGHA"
			},
			{
				"saves": 6,
				"goalsAgainst": 2,
				"savePercentage": 89.96,
				"goals": 0,
				"name": "Alex_Dahl",
				"opponent": "SHARKS",
				"date": "4/23/2023, 09:45:30 AM",
				"shotsAgainst": 8,
				"level": "MITE 2",
				"homeTeamScore": 3,
				"playerAssists": 0,
				"account": "00u4jfl64iBro689g5d7",
				"team": "BUCKEYES",
				"awayTeamScore": 2,
				"league": "OMGHA"
			},
			{
				"saves": 4,
				"goalsAgainst": 1,
				"savePercentage": 90.78,
				"goals": 0,
				"name": "Alex_Dahl",
				"opponent": "TIGERS",
				"date": "4/24/2023, 10:56:55 AM",
				"shotsAgainst": 5,
				"level": "MITE 2",
				"homeTeamScore": 2,
				"playerAssists": 0,
				"account": "00u4jfl64iBro689g5d7",
				"team": "BUCKEYES",
				"awayTeamScore": 1,
				"league": "OMGHA"
			},
			{
				"goalsAgainst"  : 2,
				"saves"         : 8,
				"savePercentage": 80.00,
				"goals"         : 0,
				"name"          : "Alex_Dahl",
				"opponent"      : "GRINDERS",
				"date"          : "4/8/2023, 6:25:59 PM",
				"shotsAgainst"  : 10,
				"homeTeamScore" : 1,
				"level"         : "2011",
				"playerAssists" : 0,
				"account"       : "00u4jfl64iBro689g5d7",
				"team"          : "PREMIER",
				"awayTeamScore" : 2,
				"league"        : "SDP"
			},
			{
				"saves"         : 19,
				"goalsAgainst"  : 1,
				"savePercentage": 95.00,
				"goals"         : 0,
				"name"          : "Alex_Dahl",
				"opponent"      : "WARRIOR",
				"date"          : "4/12/2023, 9:46:42 AM",
				"shotsAgainst"  : 20,
				"level"         : "2009",
				"homeTeamScore" : 2,
				"playerAssists" : 0,
				"account"       : "00u4jfl64iBro689g5d7",
				"team"          : "ELITE",
				"awayTeamScore" : 1,
				"league"        : "SDP"
			},
			{
				"saves"         : 9,
				"goalsAgainst"  : 1,
				"savePercentage": 90.00,
				"goals"         : 0,
				"name"          : "Alex_Dahl",
				"opponent"      : "FIRE",
				"date"          : "4/14/2023, 3:44:50 PM",
				"shotsAgainst"  : 10,
				"level"         : "2012",
				"homeTeamScore" : 4,
				"playerAssists" : 0,
				"account"       : "00u4jfl64iBro689g5d7",
				"team"          : "ELITE",
				"awayTeamScore" : 1,
				"league"        : "SDP"
			}
		]
	};
	const demo_brooks = {
		"Items": [
			{
				"saves": 1,
				"goalsAgainst": 0,
				"savePercentage": 92.50,
				"goals": 0,
				"name": "Ben_Dahl",
				"opponent": "FLAMES",
				"date": "1/1/2023, 9:00:00 AM",
				"shotsAgainst": 1,
				"level": "2008",
				"homeTeamScore": 5,
				"playerAssists": 2,
				"account": "00u4jfl64iBro689g5d7",
				"team": "ELITE",
				"awayTeamScore": 2,
				"league": "OMGHA",
				"playerShots": 0,
				"playerPlusMinus": -1
			},
			{
				"saves": 2,
				"goalsAgainst": 1,
				"savePercentage": 89.25,
				"goals": 2,
				"name": "Ben_Dahl",
				"opponent": "LIGHTNING",
				"date": "1/2/2023, 10:00:00 AM",
				"shotsAgainst": 3,
				"level": "MITE2",
				"homeTeamScore": 3,
				"playerAssists": 1,
				"account": "00u4jfl64iBro689g5d7",
				"team": "ELITE",
				"awayTeamScore": 4,
				"league": "OMGHA",
				"playerShots": 4,
				"playerPlusMinus": 0
			},
			{
				"saves": 0,
				"goalsAgainst": 3,
				"savePercentage": 82.86,
				"goals": 1,
				"name": "Ben_Dahl",
				"opponent": "STORM",
				"date": "1/3/2023, 11:00:00 AM",
				"shotsAgainst": 4,
				"level": "2008",
				"homeTeamScore": 2,
				"playerAssists": 3,
				"account": "00u4jfl64iBro689g5d7",
				"team": "ELITE",
				"awayTeamScore": 5,
				"league": "OMGHA",
				"playerShots": 3,
				"playerPlusMinus": 1
			},
			{
				"saves": 3,
				"goalsAgainst": 0,
				"savePercentage": 94.00,
				"goals": 3,
				"name": "Ben_Dahl",
				"opponent": "WAVES",
				"date": "1/4/2023, 12:00:00 PM",
				"shotsAgainst": 3,
				"level": "MITE2",
				"homeTeamScore": 4,
				"playerAssists": 2,
				"account": "00u4jfl64iBro689g5d7",
				"team": "ELITE",
				"awayTeamScore": 3,
				"league": "SDP",
				"playerShots": 5,
				"playerPlusMinus": 2
			},
			{
				"saves": 1,
				"goalsAgainst": 1,
				"savePercentage": 88.00,
				"goals": 4,
				"name": "Ben_Dahl",
				"opponent": "THUNDER",
				"date": "1/5/2023, 1:00:00 PM",
				"shotsAgainst": 2,
				"level": "2008",
				"homeTeamScore": 5,
				"playerAssists": 1,
				"account": "00u4jfl64iBro689g5d7",
				"team": "ELITE",
				"awayTeamScore": 2,
				"league": "OMGHA",
				"playerShots": 6,
				"playerPlusMinus": 3
			},
			{
				"saves": 2,
				"goalsAgainst": 0,
				"savePercentage": 90.50,
				"goals": 0,
				"name": "Ben_Dahl",
				"opponent": "HURRICANES",
				"date": "1/6/2023, 2:00:00 PM",
				"shotsAgainst": 2,
				"level": "MITE2",
				"homeTeamScore": 6,
				"playerAssists": 0,
				"account": "00u4jfl64iBro689g5d7",
				"team": "ELITE",
				"awayTeamScore": 0,
				"league": "SDP",
				"playerShots": 2,
				"playerPlusMinus": 0
			},
			{
				"saves": 0,
				"goalsAgainst": 2,
				"savePercentage": 84.62,
				"goals": 2,
				"name": "Ben_Dahl",
				"opponent": "BLIZZARD",
				"date": "1/7/2023, 3:00:00 PM",
				"shotsAgainst": 4,
				"level": "2008",
				"homeTeamScore": 3,
				"playerAssists": 3,
				"account": "00u4jfl64iBro689g5d7",
				"team": "ELITE",
				"awayTeamScore": 5,
				"league": "OMGHA",
				"playerShots": 3,
				"playerPlusMinus": 1
			},
			{
				"saves": 3,
				"goalsAgainst": 0,
				"savePercentage": 93.00,
				"goals": 1,
				"name": "Ben_Dahl",
				"opponent": "AVALANCHE",
				"date": "1/8/2023, 4:00:00 PM",
				"shotsAgainst": 3,
				"level": "MITE2",
				"homeTeamScore": 4,
				"playerAssists": 4,
				"account": "00u4jfl64iBro689g5d7",
				"team": "ELITE",
				"awayTeamScore": 3,
				"league": "SDP",
				"playerShots": 4,
				"playerPlusMinus": 2
			},

		]
	};

	const [loop, setLoop]                     = useState(0);
	const [value, setValue]                   = useState(0);
	const [isLoading, setLoading]             = useState(true);
	const [apiResponse, setApiResponse]       = useState(null);
	const [playerTable, setPlayerTable]       = useState(props.params[0].tables[0].name);
	const [playerPosition, setplayerPosition] = useState(props.params[0].tables[0].position);
	const [ondemandGAA, setOndemandGAA] = useState(0);

	useEffect(() => {
		fetchPlayerTable(playerTable);
	});

	const sortByDate = function (arr) {
		function sorter(a, b) {
			return new Date(a.date).getTime() - new Date(b.date).getTime();
		}
		return arr.sort(sorter);
	}

	const fetchPlayerTable = (player) => {
		if (loop === 0 || player !== playerTable) {
			if (player === "Alex_Dahl") {
				let SORTED_OBJ = sortByDate(demo_alex.Items);
				setApiResponse(SORTED_OBJ.reverse());
				setLoading(false);
			} else if (player === "Ben_Dahl") {
				let SORTED_OBJ = sortByDate(demo_brooks.Items);
				setApiResponse(SORTED_OBJ.reverse());
				setLoading(false);
			}
		}
		setLoop(1);
	}

	const handleChange = (event, newValue) => {
		let player   = props.params[0].tables[newValue].name;
		let position = props.params[0].tables[newValue].position;
		setLoading(true);
		setPlayerTable(player);
		setplayerPosition(position);
		setValue(newValue);
		fetchPlayerTable(player);
	};

	if (isLoading) {
		return <div className="loading">Loading...</div>
	}

	const renderPositionCharts = () => {
		if (playerPosition === 'SKATER') {
			return <SkaterMetrics {...[apiResponse]}/>
		} else {
			return <GoalieMetrics {...[apiResponse]}/>
		}
	}

	return (
		<div>
			<div className="dark_mini">
				<div>
					<small><a href={"/"} className={"white"}><b>‹</b> Return Home</a></small>
					<Typography variant="h4">Example</Typography>
					<small id={"demo_page_text"}>This is a Demo page. Changes will NOT be saved.<br/>
						<a className={"white"} href={"/#request_access"}>Sign up today</a>
						&nbsp;for a functional account.</small>
					<DemoAddGameModal params={[props.params[0].sub, playerTable]}/>
				</div>

				<Box sx={{borderBottom: 1, borderColor: 'divider'}}>
					<small className="green">Position: </small>{playerPosition}
					<Tabs value={value}
						  onChange={handleChange}
						  variant="scrollable"
						  scrollButtons="on"
						  indicatorColor="secondary">
						{props.params[0].tables.map((table, i) => (
							<Tab key={i} label={table.name.split('_')[0] + " " + table.name.split('_')[1]}/>
						))}
					</Tabs>
				</Box>
			</div>

			<Accordion className="accord_pop">
				<AccordionSummary
					expandIcon="⥥"
					aria-controls="panel1a-content"
					id="panel1a-header">
					<TimelineIcon/><Typography variant="body1">&nbsp;Tap To Reveal Data Trends</Typography>
				</AccordionSummary>
				{/*<AccordionDetails>*/}
				{renderPositionCharts()}
				{/*</AccordionDetails>*/}
			</Accordion>

			{
				apiResponse.map((item, i) => (
					<div style={{padding: "30px 10px 10px 10px"}} className={"ledger_block"}>
						<p>{item.league === "SDP" ? "AAA" : item.league} {item.level} on <i>{item.date.split(',')[0]}</i><DemoUpdateRecordModal params={[item, playerTable, playerPosition]}/></p>
						<DemoDeleteRecordModal params={[item, playerTable]}/>
						<Grid container style={{margin: "20px 5px"}}>
							<Grid xs={5} className={"pt-1 center_text"}>
								<div className={"team_score_titles pt-5"}>
									<h3>
										<span className={`${(item.homeTeamScore > item.awayTeamScore) ? 'green_highlight' : 'red_highlight'}`}>{item.team}: <b>{item.homeTeamScore}</b></span>
										<br/>
										<i>vs.</i>
										<br/>
										<span>{item.opponent}: <b>{item.awayTeamScore}</b></span>
									</h3>
								</div>
							</Grid>
							<Grid xs={3}>
								<Grid xs={12} className={"border-right border-bottom pt-5 pb-10"}>
									<Grid container>
										<Grid xs={12} className={"center_text"}>
											{(playerPosition === "SKATER") ? (<div>Goals</div>) : (<div>GA</div>)}
										</Grid>
										<Grid xs={12} className={"center_text"}>
											{(playerPosition === "SKATER") ? (<div><b>{item.goals}</b></div>) : (<div><b>{item.goalsAgainst}</b></div>)}
										</Grid>
									</Grid>
								</Grid>
								<Grid xs={12} className={"border-right pt-5 pb-5"}>
									<Grid container>
										<Grid xs={12} className={"center_text"}>
											{(playerPosition === "SKATER") ? (<div>Shots</div>) : (<div>SA</div>)}
										</Grid>
										<Grid xs={12} className={"center_text"}>
											{(playerPosition === "SKATER") ? (<div><b>{item.playerShots}</b></div>) : (<div><b>{item.shotsAgainst}</b></div>)}
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid xs={3}>
								<Grid xs={12} className={"border-bottom pt-5 pb-10"}>
									<Grid container>
										<Grid xs={12} className={"center_text"}>
											{(playerPosition === "SKATER") ? (<div>Assists</div>) : (<div>S%</div>)}
										</Grid>
										<Grid xs={12} className={"center_text"}>
											{(playerPosition === "SKATER") ? (<div><b>{item.playerAssists}</b></div>) : (<div><b>{item.savePercentage}</b></div>)}
										</Grid>
									</Grid>
								</Grid>
								<Grid xs={12} className={"pt-5 pb-5"}>
									<Grid container>
										<Grid xs={12} className={"center_text"}>
											{(playerPosition === "SKATER") ? (<div>+/-</div>) : (<div>GAA</div>)}
										</Grid>
										<Grid xs={12} className={"center_text"}>
											{(playerPosition === "SKATER") ? (<div><b>{item.playerPlusMinus}</b></div>) : (<div><b>{parseFloat(item.awayTeamScore / (i+1)).toFixed(1)}</b></div>)}
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid xs={1}></Grid>
						</Grid>
					</div>
				))
			}
		</div>
	);
}

export default RenderResult;
