import * as React    from 'react';
import {
	Box, Button, FormControl, FormHelperText, InputLabel, MenuItem,
	Modal, Select,
	TextField
}                    from '@material-ui/core';
import '../global.css';
import ErrorSnackbar from "./ErrorSnackbar";

const style = {
	position : 'absolute',
	top      : '50%',
	left     : '50%',
	transform: 'translate(-50%, -50%)',
	width    : 400,
	bgcolor  : 'background.paper',
	border   : '2px solid #000',
	boxShadow: 24,
	p        : 4,
};


export default function AddGameModal(params) {

	console.log('DemoAddGameModal ', params.params[0], params.params[1]);

	const demo_leagues = {
		"Items": [
			{
				"levels": [
					{
						"teams": [
							"NORTH",
							"SOUTH",
							"EAST",
							"WEST"
						],
						"level": "Mite, Preview Challenge Cup"
					},
					{
						"teams": [
							"RED",
							"BLACK",
							"BLUE",
							"DARK BLUE",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN",
							"GOLD"
						],
						"level": "2015 AAA, Stars & Stripes"
					},
					{
						"teams": [
							"RED",
							"BLACK",
							"BLUE",
							"DARK BLUE",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN",
							"GOLD"
						],
						"level": "3v3"
					},
					{
						"teams": [
							"RED",
							"BLACK",
							"BLUE",
							"DARK BLUE",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN",
							"GOLD"
						],
						"level": "Choice"
					},
					{
						"teams": [
							"RED",
							"BLACK",
							"BLUE",
							"DARK BLUE",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN",
							"GOLD"
						],
						"level": "OTHER"
					}
				],
				"league": "MN MADE"
			},
			{
				"levels": [
					{
						"teams": [
							"SDP PREMIER",
							"SDP ELITE",
							"BLADES",
							"MACHINE",
							"LAKERS",
							"WARRIORS",
							"RAPID FIRE",
							"DAGGERS",
							"WILD",
							"GRINDERS",
							"RED",
							"BLACK",
							"BLUE",
							"DARK BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN"
						],
						"level": "OTHER"
					}
				],
				"league": "AAA"
			},
			{
				"levels": [
					{
						"teams": [
							"RED",
							"BLACK",
							"BLUE",
							"DARK BLUE",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN"
						],
						"level": "OTHER"
					}
				],
				"league": "OTHER"
			},
			{
				"levels": [
					{
						"teams": [
							"ORIOLES",
							"CRIMSON",
							"TIGERS",
							"MAVERICKS",
							"RED",
							"BLACK",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN",
							"PURPLE"
						],
						"level": "MINI MITE"
					},
					{
						"teams": [
							"BEAVERS",
							"BLACK BEARS",
							"BULLDOGS",
							"TIGERS",
							"MAVERICKS",
							"HUSKIES",
							"RED",
							"BLACK",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN",
							"PURPLE"
						],
						"level": "MITE 1"
					},
					{
						"teams": [
							"BADGERS",
							"BUCKEYES",
							"GOPHERS",
							"LIONS",
							"SPARTANS",
							"WOLVERINES",
							"RED",
							"BLACK",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN",
							"PURPLE"
						],
						"level": "MITE 2"
					},
					{
						"teams": [
							"HURRICANES",
							"FLYERS",
							"GOLDEN KNIGHTS",
							"WILD",
							"OILERS",
							"SHARKS",
							"RANGERS",
							"LIGHTNING",
							"PENGUINS",
							"ISLANDERS",
							"RED",
							"BLACK",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN",
							"PURPLE"
						],
						"level": "SUPERMITE"
					},
					{
						"teams": [
							"SKY",
							"PURPLE",
							"CRIMSON",
							"RED",
							"BLACK",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN"
						],
						"level": "GM"
					},
					{
						"teams": [
							"BEAUTS",
							"PRIDE",
							"RIVETERS",
							"SIX",
							"WHALE",
							"WHITECAPS",
							"RED",
							"BLACK",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN",
							"PURPLE"
						],
						"level": "U8"
					},
					{
						"teams": [
							"ORANGE",
							"CRIMSON",
							"RED",
							"BLACK",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"WHITE",
							"BROWN",
							"PURPLE"
						],
						"level": "U10 B2"
					},
					{
						"teams": [
							"WHITE",
							"BLACK",
							"RED",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN",
							"PURPLE"
						],
						"level": "U10 B1"
					},
					{
						"teams": [
							"CRIMSON",
							"RED",
							"BLACK",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN",
							"PURPLE"
						],
						"level": "U10 A"
					},
					{
						"teams": [
							"CRIMSON",
							"BLACK",
							"RED",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN",
							"PURPLE"
						],
						"level": "U12 B2"
					},
					{
						"teams": [
							"WHITE",
							"RED",
							"BLACK",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"BROWN",
							"PURPLE"
						],
						"level": "U12 B1"
					},
					{
						"teams": [
							"CRIMSON",
							"RED",
							"BLACK",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN",
							"PURPLE"
						],
						"level": "U12 A"
					},
					{
						"teams": [
							"WHITE",
							"BLACK",
							"RED",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"BROWN",
							"PURPLE"
						],
						"level": "U15 B"
					},
					{
						"teams": [
							"CRIMSON",
							"RED",
							"BLACK",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN",
							"PURPLE"
						],
						"level": "U15 A"
					},
					{
						"teams": [
							"GOLD",
							"BLACK",
							"RED",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN",
							"PURPLE"
						],
						"level": "SQUIRT C"
					},
					{
						"teams": [
							"CRIMSON",
							"MAROON",
							"ORANGE",
							"RED",
							"BLACK",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"WHITE",
							"BROWN",
							"PURPLE"
						],
						"level": "SQUIRT B2"
					},
					{
						"teams": [
							"BLACK",
							"CRIMSON",
							"GOLD",
							"RED",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN",
							"PURPLE"
						],
						"level": "SQUIRT B1"
					},
					{
						"teams": [
							"WHITE",
							"CRIMSON",
							"RED",
							"BLACK",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"BROWN",
							"PURPLE"
						],
						"level": "SQUIRT A"
					},
					{
						"teams": [
							"BLACK",
							"GOLD",
							"RED",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN",
							"PURPLE"
						],
						"level": "PEEWEE C"
					},
					{
						"teams": [
							"CRIMSON",
							"GOLD",
							"MAROON",
							"RED",
							"BLACK",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN",
							"PURPLE"
						],
						"level": "PEEWEE B2"
					},
					{
						"teams": [
							"WHITE",
							"BLACK",
							"RED",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"BROWN",
							"PURPLE"
						],
						"level": "PEEWEE B1"
					},
					{
						"teams": [
							"CRIMSON",
							"RED",
							"BLACK",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN",
							"PURPLE"
						],
						"level": "PEEWEE A"
					},
					{
						"teams": [
							"CRIMSON",
							"RED",
							"BLACK",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN",
							"PURPLE"
						],
						"level": "PEEWEE AA"
					},
					{
						"teams": [
							"WHITE",
							"BLACK",
							"RED",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"BROWN",
							"PURPLE"
						],
						"level": "BANTAM C"
					},
					{
						"teams": [
							"CRIMSON",
							"GOLD",
							"RED",
							"BLACK",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN",
							"PURPLE"
						],
						"level": "BANTAM B2"
					},
					{
						"teams": [
							"BLACK",
							"WHITE",
							"RED",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"BROWN",
							"PURPLE"
						],
						"level": "BANTAM B1"
					},
					{
						"teams": [
							"CRIMSON",
							"RED",
							"BLACK",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN",
							"PURPLE"
						],
						"level": "BANTAM A"
					},
					{
						"teams": [
							"CRIMSON",
							"RED",
							"BLACK",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN",
							"PURPLE"
						],
						"level": "BANTAM AA"
					},
					{
						"teams": [
							"CRIMSON",
							"RED",
							"BLACK",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN",
							"PURPLE"
						],
						"level": "JUNIOR GOLD U16"
					},
					{
						"teams": [
							"CRIMSON",
							"RED",
							"BLACK",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN",
							"PURPLE"
						],
						"level": "JUNIOR GOLD B"
					},
					{
						"teams": [
							"CRIMSON",
							"RED",
							"BLACK",
							"BLUE",
							"NAVY",
							"GREEN",
							"GRAY",
							"YELLOW",
							"PINK",
							"ORANGE",
							"WHITE",
							"BROWN",
							"PURPLE"
						],
						"level": "JUNIOR GOLD A"
					}
				],
				"league": "ASSOC"
			}
		],
		"Count": 6,
		"ScannedCount": 6
	}

	const SUB                                         = params.params[0];
	const PLAYER                                      = params.params[1];
	const [open, setOpen]                             = React.useState(false);
	const [date]                                      = React.useState(new Date().toLocaleString());
	const [leaguesApiResponse, setLeaguesApiResponse] = React.useState(demo_leagues.Items);
	const [league, setLeague]                         = React.useState("");
	const [leagues, setLeagues]                       = React.useState([]);
	const [selectedLeague, setSelectedLeague]         = React.useState([]);
	const [level, setLevel]                           = React.useState("");
	const [levels, setLevels]                         = React.useState([]);
	const [opponent, setOpponent]                     = React.useState("");
	const [team, setTeam]                             = React.useState("");
	const [teams, setTeams]                           = React.useState([]);
	const [error, setError]                           = React.useState(null);

	const handleOpen = () => {
		setOpen(true);
		fetchTeams();
	}


	const handleClose = () => {
		setOpen(false);
		setLeague("");
		setLeagues([]);
		setSelectedLeague([]);
		setLevel("");
		setLevels([]);
		setOpponent("");
		setTeam("");
		setTeams([]);
	}

	const computeLeagues = (res) => {
		let all_leagues = [];

		for (let i = 0; i < res.length; i++) {
			console.log('League = ', res[i]);
			all_leagues.push(res[i].league);
		}

		console.log('all_leagues = ', all_leagues);
		setLeagues(all_leagues);

		getLevelsInSelectedLeague();
	}

	const getLevelsInSelectedLeague = (league) => {
		let levels_in_selected_league = [];

		console.log('league data = ', leaguesApiResponse);
		for (let i = 0; i < leaguesApiResponse.length; i++) {
			if (leaguesApiResponse[i].league === league) {
				console.log('league matched so grab all associated levels ', leaguesApiResponse[i].levels);

				setSelectedLeague(leaguesApiResponse[i].levels);

				let league_levels = leaguesApiResponse[i].levels;
				for (let ii = 0; ii < league_levels.length; ii++) {
					levels_in_selected_league.push(league_levels[ii].level);
				}
			}
		}

		setLevels(levels_in_selected_league);
	}

	const getTeamsInSelectedLevel = (level) => {
		let teams_in_selected_level = [];

		console.log('level data = ', level, " leagueData = ", selectedLeague);
		for (let i = 0; i < selectedLeague.length; i++) {
			if (selectedLeague[i].level === level) {
				console.log('level matched so grab all associated teams ', selectedLeague[i].teams);

				let level_teams = selectedLeague[i].teams;
				for (let ii = 0; ii < level_teams.length; ii++) {
					teams_in_selected_level.push(level_teams[ii]);
				}
			}
		}
		console.log('teams_in_selected_level = ', teams_in_selected_level)
		setTeams(teams_in_selected_level);
	}

	const fetchTeams = () => {
		console.log('fetchTeams');

		setLeaguesApiResponse(demo_leagues.Items);
		computeLeagues(demo_leagues.Items);
	}

	const getDate = () => {
		console.log(date);
		return date;
	}

	const createNewGameRecord = () => {
		console.log('SAVE>....', league, ' ', SUB, ' ', PLAYER, ' ', team);
		handleClose();
	}

	function handleChange(val, field) {
		// console.log('handleChange field=', e);
		console.log('handleChange field=', field, ' //// value=', val)
		if (field === "league") {
			setLeague(val);
			getLevelsInSelectedLeague(val);
		}

		if (field === "level") {
			setLevel(val);
			getTeamsInSelectedLevel(val);
		}

		if (field === "team") {
			setTeam(val);
		}

		if (field === "opponent") {
			setOpponent(val);
		}
	}

	return (
		<div className="add_game_modal">
			<Button variant="contained" id={"add-new-game"} data-test={"add_new_game"} onClick={handleOpen}>
				Add New Game
			</Button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className="custom_modal_styles"
			>
				<Box sx={style}>
					<Button size="small" color="secondary" variant="outlined" onClick={handleClose}>
						Close
					</Button>
					<h4>Add New Game for {PLAYER}</h4>
					<FormControl fullWidth>
						<InputLabel id="league-select-label" data-test={"League"}>League <sup>*</sup></InputLabel>
						<Select
							labelId="league-select-label"
							id="league"
							value={league}
							label="League"
							onChange={(e) => handleChange(e.target.value, "league")}
						>
							{leagues.map((item, i) => (
								<MenuItem key={i} value={item} data-test={"League" + i}>{item}</MenuItem>
							))}
						</Select>
						<FormHelperText>Required</FormHelperText>
					</FormControl>
					<br/>
					<br/>
					<FormControl fullWidth>
						<InputLabel id="level-select-label" data-test="Level">Level <sup>*</sup></InputLabel>
						<Select
							labelId="level-select-label"
							id="level"
							value={level}
							label="Level"
							onChange={(e) => handleChange(e.target.value, "level")}
						>
							{levels.map((item, i) => (
								<MenuItem key={i} value={item} data-test={"Level" + i}>{item}</MenuItem>
							))}
						</Select>
						<FormHelperText>Required</FormHelperText>
					</FormControl>
					<br/>
					<br/>
					<FormControl fullWidth>
						<InputLabel id="team-select-label" data-test="Team">Team <sup>*</sup></InputLabel>
						<Select
							labelId="team-select-label"
							id="team"
							value={team}
							label="Team"
							onChange={(e) => handleChange(e.target.value, "team")}
						>
							{teams.map((item, i) => (
								<MenuItem key={i} value={item} data-test={"Team" + i}>{item}</MenuItem>
							))}
						</Select>
						<FormHelperText>Required</FormHelperText>
					</FormControl>

					<br/>
					<br/>
					<TextField id="opponent" label="Opponent Team Name *" variant="outlined"
							   onChange={(e) => handleChange(e.target.value, "opponent")}/>
					<p className="MuiFormHelperText-root">Required</p>
					<br/>
					<br/>
					<Button className={"SaveGame"}
							variant="contained"
							onClick={createNewGameRecord}>Save</Button>
				</Box>
			</Modal>

			<br/>
			<br/>
			{error && (
				<ErrorSnackbar
					onClose={() => setError(null)}
					message={error}
				/>
			)}
		</div>
	);
}
