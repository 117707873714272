import React, {Fragment}            from 'react';
import {Route}                      from 'react-router-dom';
import {SecureRoute, LoginCallback} from '@okta/okta-react';
import {
	CssBaseline,
	withStyles,
}                                   from '@material-ui/core';

import AppHeader          from './components/AppHeader';
import Home               from './pages/Home';
// import Landing            from './pages/Landing';
import PostsManager       from './pages/PostsManager';
import TermsAndConditions from "./pages/TermsAndConditions";
import ThankYou         from "./pages/ThankYou";
import PrivacyPolicy              from "./pages/PrivacyPolicy";
import Car                        from "./pages/Car";
import Car_redirect                   from "./pages/Car_redirect";
import Ad                             from "./pages/Ad";
import Ad_redirect                    from "./pages/Ad_redirect";
import Profile                        from "./pages/Profile";
import ContactUs                      from "./pages/ContactUs";
import AppFooter                      from "./components/AppFooter";
import DemoPostsManager               from "./pages/DemoPostsManager";
import Checkout                       from "./pages/Checkout";
import Advertise                      from "./pages/Advertise";
import MNMadeSouthDasherAdToBitly     from "./pages/MNMadeSouthDasherAdToBitly";
import MNMadeNorthDasherAdToBitly     from "./pages/MNMadeNorthDasherAdToBitly";
import Ad_mnmade_edina_south_redirect from "./pages/Ad_mnmade_edina_south_redirect";
import Ad_mnmade_edina_north_redirect from "./pages/Ad_mnmade_edina_north_redirect";

const styles = theme => ({
	main: {
		padding                       : theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(2),
		},
	},
});

let MNMadeNouthDasherAdToBitly;
const App = ({classes}) => (
	<Fragment>
		<CssBaseline/>
		<AppHeader/>
		<main className={classes.main}>
			<Route exact path="/car" component={Car}/>
			<Route exact path="/car_redirect" component={Car_redirect}/>
			<Route exact path="/ad" component={Ad}/>
			<Route exact path="/ad/mnmade/edina/south" component={MNMadeSouthDasherAdToBitly}/>
			<Route exact path="/ad/mnmade/edina/north" component={MNMadeNorthDasherAdToBitly}/>
			<Route exact path="/made/edina/south" component={Ad_mnmade_edina_south_redirect}/>
			<Route exact path="/made/edina/north" component={Ad_mnmade_edina_north_redirect}/>
			<Route exact path="/ad_redirect" component={Ad_redirect}/>
			<Route exact path="/terms-and-conditions" component={TermsAndConditions}/>
			<Route exact path="/privacy-policy" component={PrivacyPolicy}/>
			<Route exact path="/" component={Home}/>
			<Route path="/landing" component={Home}/>
			<Route path="/thank-you" component={ThankYou}/>
			<Route path="/advertise" component={Advertise}/>
			<Route path="/checkout" component={Checkout}/>
			<Route path="/demo" component={DemoPostsManager}/>
			<SecureRoute path="/profile" component={Profile}/>
			<SecureRoute path="/contact" component={ContactUs}/>
			<SecureRoute path="/statz" component={PostsManager}/>
			<Route path="/login/callback" component={LoginCallback}/>
		</main>
		<AppFooter/>
	</Fragment>
);

export default withStyles(styles)(App);