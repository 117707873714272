import * as React from 'react';
import {
	Box,
	Modal
}                 from '@material-ui/core';
import axios      from "axios";
import '../global.css';
import {
	RemoveCircleRounded
} from '@material-ui/icons';
// import {
// 	AdapterDateFns,
// 	LocalizationProvider,
// 	DatePicker
// } from '@material-ui/lab';

const style = {
	position : 'absolute',
	top      : '50%',
	left     : '50%',
	transform: 'translate(-50%, -50%)',
	width    : 400,
	bgcolor  : 'background.paper',
	border   : '2px solid #000',
	boxShadow: 24,
	p        : 4,
};


export default function DeleteRecordModal(item) {
	console.log('DeleteRecordModal ', item);

	const playerTable     = item.params[1];
	const [open, setOpen] = React.useState(false);
	const handleOpen      = () => setOpen(true);
	const handleClose     = () => setOpen(false);
	const url             = process.env.REACT_APP_ANYTHING_API;
	const KEY             = process.env.REACT_APP_ANYTHING_KEY;
	const headers         = {
		'content-type': 'application/json',
		accept        : 'application/json',
		'x-api-key'   : `${KEY}`
	};

	function deleteRecord(param) {
		console.log('delete ', param.params[0]);
		axios({
				  method : 'delete',
				  url    : url,
				  headers: headers,
				  data   : {
					  "Key"      : {
						  "date": param.params[0].date,
						  "league": param.params[0].league
					  },
					  "TableName": playerTable
				  }
			  })
			.then(success => {
				console.log('success::: ', success);
				window.location.reload();
			})
			.catch(error => {
				console.warn("error::: ", error);
			});
	}

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<h2>Are you sure you want to delete this?</h2>
					<p>All data associated with the game on {item.params[0].date} will be lost.</p>
					<div className={"delete_game_confirm"} onClick={() => deleteRecord(item)}>
						DELETE
					</div>
				</Box>

			</Modal>
			<span className="delete_game" onClick={handleOpen}>Delete Game</span>
		</div>
	);
}
