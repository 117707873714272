import React from 'react';
import {
	withStyles
} from '@material-ui/core';
import '../global.css';

const styles = {
	flex: {
		flex: 1,
	},
};

const AppFooter = ({classes}) => (
	<div className="footer center_text">
		<small>
			<a href="/terms-and-conditions">Terms & Conditions</a>
		</small>
	</div>
);

export default withStyles(styles)(AppFooter);