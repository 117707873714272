import React         from 'react';

import '../global.css';
import email_example from "../images/email_example.jpg";

export default () => (
	<div className="p-15">
		<h1>
			Advertise With Us
		</h1>
		<p>
			<ul>
				<li>Total Ad Spaces: 2</li>
				<li>Total Ad Spaces Taken: 0</li>
				<li>Total Ad Spaces Available: 2</li>
			</ul>
		</p>
		<p>
			<ul>
				<li>Cost: $5,000 Annually, Per Unique Artwork</li>
			</ul>
		</p>
		{/*<img src={email_example} width="70%" alt={""}/>*/}
	</div>
);